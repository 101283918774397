<div class="form-group m-auto">
<span id="error-validation" *ngIf="control.touched && errors && errors[0]"></span>

<label class="size-bold" *ngIf="text != ''">{{ text + (isRequired ? '*' : '') }}</label>

<p-autoComplete
  placeholder="{{ text + (isRequired ? '*' : '') }}"
  (focusout)="touch()"
  #input
  [(ngModel)]="selectedItem"
  (onSelect)="onChange($event, selectedItem)"
  *ngIf="isRender"
  [suggestions]="filter"
  (completeMethod)="search($event)"
  (onClear)="search($event)"
  field="label"
  dataKey="value"
  [dropdown]="true"
>
  <ng-template let-country pTemplate="item">
    <div *ngIf="country.value !== filter[filter.length - 1].value || !allowAddingNewItem" class="country-item">
      <div class="d-flex">
        <img *ngIf="hasImage" width="100px" src="{{ country.image }}" />
        <div *ngIf="hasColorBox" style="height: 30px; width: 30px" [style.background]="country.colorCode"></div>
        <span
          *ngIf="hasImage || hasColorBox"
          style="margin-top: auto; margin-bottom: auto; margin-left: 15px; margin-right: 15px"
        >
          {{ country.label }}</span
        >
        <span *ngIf="!hasImage && !hasColorBox"> {{ country.label }}</span>
        <!-- <span  > <img (click)="deleteItem(1)" src="assets/img/delete_icn.png"> </span> -->
      </div>
    </div>
    <div *ngIf="country.value === filter[filter.length - 1].value && allowAddingNewItem" class="country-item">
      <div>
        <span class="pr-2"><img src="assets/img/Plus.png" /></span> <span>Add new item </span>
      </div>
    </div>
  </ng-template>
</p-autoComplete>

<div class="d-content" *ngIf="control.invalid && control.touched" style="height: 11px" class="text-danger">
  <p style="text-align: initial" class="text-danger" *ngFor="let error of errors">{{ error }}</p>
</div>
</div>
