/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetAllStylistWithdrawalDto } from '../models/get-all-stylist-withdrawal-dto';
import { OutputGetAllAgeDto } from '../models/output-get-all-age-dto';
import { OutputGetAllStylesDto } from '../models/output-get-all-styles-dto';
import { OutputGetAllStylistCollectionDto } from '../models/output-get-all-stylist-collection-dto';
import { OutputGetStylistBankAccountDto } from '../models/output-get-stylist-bank-account-dto';
import { OutputGetStylistProfileDto } from '../models/output-get-stylist-profile-dto';
import { OutputGetStylistUpdateDto } from '../models/output-get-stylist-update-dto';
import { OutputGetStylistWorkDto } from '../models/output-get-stylist-work-dto';
import { OutputStylistAnalytics } from '../models/output-stylist-analytics';
import { OutputStylistEarning } from '../models/output-stylist-earning';
import { StylistDashboardDto } from '../models/stylist-dashboard-dto';
import { stylistProfileManagementCompeleteStylistInformation } from '../fn/stylist-profile-management/stylist-profile-management-compelete-stylist-information';
import { StylistProfileManagementCompeleteStylistInformation$Params } from '../fn/stylist-profile-management/stylist-profile-management-compelete-stylist-information';
import { stylistProfileManagementCreateStylistBankAccount } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-bank-account';
import { StylistProfileManagementCreateStylistBankAccount$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-bank-account';
import { stylistProfileManagementCreateStylistBankAccount$Plain } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-bank-account-plain';
import { StylistProfileManagementCreateStylistBankAccount$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-bank-account-plain';
import { stylistProfileManagementCreateStylistCollection } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-collection';
import { StylistProfileManagementCreateStylistCollection$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-collection';
import { stylistProfileManagementCreateStylistCollection$Plain } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-collection-plain';
import { StylistProfileManagementCreateStylistCollection$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-collection-plain';
import { stylistProfileManagementCreateStylistDraft } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-draft';
import { StylistProfileManagementCreateStylistDraft$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-draft';
import { stylistProfileManagementCreateStylistWiithdrawal } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-wiithdrawal';
import { StylistProfileManagementCreateStylistWiithdrawal$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-wiithdrawal';
import { stylistProfileManagementCreateStylistWiithdrawal$Plain } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-wiithdrawal-plain';
import { StylistProfileManagementCreateStylistWiithdrawal$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-wiithdrawal-plain';
import { stylistProfileManagementCreateStylistWork } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-work';
import { StylistProfileManagementCreateStylistWork$Params } from '../fn/stylist-profile-management/stylist-profile-management-create-stylist-work';
import { stylistProfileManagementDeleteStylistCollection } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-collection';
import { StylistProfileManagementDeleteStylistCollection$Params } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-collection';
import { stylistProfileManagementDeleteStylistCollection$Plain } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-collection-plain';
import { StylistProfileManagementDeleteStylistCollection$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-collection-plain';
import { stylistProfileManagementDeleteStylistWork } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-work';
import { StylistProfileManagementDeleteStylistWork$Params } from '../fn/stylist-profile-management/stylist-profile-management-delete-stylist-work';
import { stylistProfileManagementGetAllAge } from '../fn/stylist-profile-management/stylist-profile-management-get-all-age';
import { StylistProfileManagementGetAllAge$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-age';
import { stylistProfileManagementGetAllAge$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-age-plain';
import { StylistProfileManagementGetAllAge$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-age-plain';
import { stylistProfileManagementGetAllStyle } from '../fn/stylist-profile-management/stylist-profile-management-get-all-style';
import { StylistProfileManagementGetAllStyle$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-style';
import { stylistProfileManagementGetAllStyle$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-style-plain';
import { StylistProfileManagementGetAllStyle$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-style-plain';
import { stylistProfileManagementGetAllStylistCollection } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-collection';
import { StylistProfileManagementGetAllStylistCollection$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-collection';
import { stylistProfileManagementGetAllStylistCollection$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-collection-plain';
import { StylistProfileManagementGetAllStylistCollection$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-collection-plain';
import { stylistProfileManagementGetAllStylistPendingCollection } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-pending-collection';
import { StylistProfileManagementGetAllStylistPendingCollection$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-pending-collection';
import { stylistProfileManagementGetAllStylistPendingCollection$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-pending-collection-plain';
import { StylistProfileManagementGetAllStylistPendingCollection$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-pending-collection-plain';
import { stylistProfileManagementGetAllStylistWithdrawal } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-withdrawal';
import { StylistProfileManagementGetAllStylistWithdrawal$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-withdrawal';
import { stylistProfileManagementGetAllStylistWithdrawal$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-withdrawal-plain';
import { StylistProfileManagementGetAllStylistWithdrawal$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-withdrawal-plain';
import { stylistProfileManagementGetAllStylistWork } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-work';
import { StylistProfileManagementGetAllStylistWork$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-work';
import { stylistProfileManagementGetAllStylistWork$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-work-plain';
import { StylistProfileManagementGetAllStylistWork$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-all-stylist-work-plain';
import { stylistProfileManagementGetOneStylistWork } from '../fn/stylist-profile-management/stylist-profile-management-get-one-stylist-work';
import { StylistProfileManagementGetOneStylistWork$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-one-stylist-work';
import { stylistProfileManagementGetOneStylistWork$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-one-stylist-work-plain';
import { StylistProfileManagementGetOneStylistWork$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-one-stylist-work-plain';
import { stylistProfileManagementGetStylistAnalytics } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-analytics';
import { StylistProfileManagementGetStylistAnalytics$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-analytics';
import { stylistProfileManagementGetStylistAnalytics$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-analytics-plain';
import { StylistProfileManagementGetStylistAnalytics$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-analytics-plain';
import { stylistProfileManagementGetStylistBankAccount } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-bank-account';
import { StylistProfileManagementGetStylistBankAccount$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-bank-account';
import { stylistProfileManagementGetStylistBankAccount$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-bank-account-plain';
import { StylistProfileManagementGetStylistBankAccount$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-bank-account-plain';
import { stylistProfileManagementGetStylistDashboard } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-dashboard';
import { StylistProfileManagementGetStylistDashboard$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-dashboard';
import { stylistProfileManagementGetStylistDashboard$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-dashboard-plain';
import { StylistProfileManagementGetStylistDashboard$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-dashboard-plain';
import { stylistProfileManagementGetStylistDraft } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-draft';
import { StylistProfileManagementGetStylistDraft$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-draft';
import { stylistProfileManagementGetStylistDraft$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-draft-plain';
import { StylistProfileManagementGetStylistDraft$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-draft-plain';
import { stylistProfileManagementGetStylistEarning } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-earning';
import { StylistProfileManagementGetStylistEarning$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-earning';
import { stylistProfileManagementGetStylistEarning$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-earning-plain';
import { StylistProfileManagementGetStylistEarning$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-earning-plain';
import { stylistProfileManagementGetStylistForUpdate } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-for-update';
import { StylistProfileManagementGetStylistForUpdate$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-for-update';
import { stylistProfileManagementGetStylistForUpdate$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-for-update-plain';
import { StylistProfileManagementGetStylistForUpdate$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-for-update-plain';
import { stylistProfileManagementGetStylistProfile } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-profile';
import { StylistProfileManagementGetStylistProfile$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-profile';
import { stylistProfileManagementGetStylistProfile$Plain } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-profile-plain';
import { StylistProfileManagementGetStylistProfile$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-get-stylist-profile-plain';
import { stylistProfileManagementUpdateCoverImage } from '../fn/stylist-profile-management/stylist-profile-management-update-cover-image';
import { StylistProfileManagementUpdateCoverImage$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-cover-image';
import { stylistProfileManagementUpdateDraftStatus } from '../fn/stylist-profile-management/stylist-profile-management-update-draft-status';
import { StylistProfileManagementUpdateDraftStatus$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-draft-status';
import { stylistProfileManagementUpdateProfileImage } from '../fn/stylist-profile-management/stylist-profile-management-update-profile-image';
import { StylistProfileManagementUpdateProfileImage$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-profile-image';
import { stylistProfileManagementUpdateStylist } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist';
import { StylistProfileManagementUpdateStylist$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist';
import { stylistProfileManagementUpdateStylistCollectionPendingType } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-collection-pending-type';
import { StylistProfileManagementUpdateStylistCollectionPendingType$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-collection-pending-type';
import { stylistProfileManagementUpdateStylistCollectionPendingType$Plain } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-collection-pending-type-plain';
import { StylistProfileManagementUpdateStylistCollectionPendingType$Plain$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-collection-pending-type-plain';
import { stylistProfileManagementUpdateStylistInformation } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-information';
import { StylistProfileManagementUpdateStylistInformation$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-information';
import { stylistProfileManagementUpdateStylistWork } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-work';
import { StylistProfileManagementUpdateStylistWork$Params } from '../fn/stylist-profile-management/stylist-profile-management-update-stylist-work';

@Injectable({ providedIn: 'root' })
export class StylistProfileManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `stylistProfileManagementGetStylistForUpdate()` */
  static readonly StylistProfileManagementGetStylistForUpdatePath = '/api/customer/StylistProfileManagement/GetStylistForUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistForUpdate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Plain$Response(params?: StylistProfileManagementGetStylistForUpdate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistUpdateDto>> {
    return stylistProfileManagementGetStylistForUpdate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistForUpdate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Plain(params?: StylistProfileManagementGetStylistForUpdate$Plain$Params, context?: HttpContext): Observable<OutputGetStylistUpdateDto> {
    return this.stylistProfileManagementGetStylistForUpdate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistUpdateDto>): OutputGetStylistUpdateDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistForUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Response(params?: StylistProfileManagementGetStylistForUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistUpdateDto>> {
    return stylistProfileManagementGetStylistForUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistForUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate(params?: StylistProfileManagementGetStylistForUpdate$Params, context?: HttpContext): Observable<OutputGetStylistUpdateDto> {
    return this.stylistProfileManagementGetStylistForUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistUpdateDto>): OutputGetStylistUpdateDto => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateStylist()` */
  static readonly StylistProfileManagementUpdateStylistPath = '/api/customer/StylistProfileManagement/UpdateStylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylist$Response(params?: StylistProfileManagementUpdateStylist$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateStylist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylist(params?: StylistProfileManagementUpdateStylist$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateStylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistAnalytics()` */
  static readonly StylistProfileManagementGetStylistAnalyticsPath = '/api/customer/StylistProfileManagement/GetStylistAnalytics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistAnalytics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Plain$Response(params?: StylistProfileManagementGetStylistAnalytics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputStylistAnalytics>> {
    return stylistProfileManagementGetStylistAnalytics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistAnalytics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Plain(params?: StylistProfileManagementGetStylistAnalytics$Plain$Params, context?: HttpContext): Observable<OutputStylistAnalytics> {
    return this.stylistProfileManagementGetStylistAnalytics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputStylistAnalytics>): OutputStylistAnalytics => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistAnalytics()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Response(params?: StylistProfileManagementGetStylistAnalytics$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputStylistAnalytics>> {
    return stylistProfileManagementGetStylistAnalytics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistAnalytics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics(params?: StylistProfileManagementGetStylistAnalytics$Params, context?: HttpContext): Observable<OutputStylistAnalytics> {
    return this.stylistProfileManagementGetStylistAnalytics$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputStylistAnalytics>): OutputStylistAnalytics => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateProfileImage()` */
  static readonly StylistProfileManagementUpdateProfileImagePath = '/api/customer/StylistProfileManagement/UpdateProfileImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateProfileImage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateProfileImage$Response(params?: StylistProfileManagementUpdateProfileImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateProfileImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateProfileImage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateProfileImage(params?: StylistProfileManagementUpdateProfileImage$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateProfileImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCompeleteStylistInformation()` */
  static readonly StylistProfileManagementCompeleteStylistInformationPath = '/api/customer/StylistProfileManagement/CompeleteStylistInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCompeleteStylistInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCompeleteStylistInformation$Response(params?: StylistProfileManagementCompeleteStylistInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementCompeleteStylistInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCompeleteStylistInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCompeleteStylistInformation(params?: StylistProfileManagementCompeleteStylistInformation$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementCompeleteStylistInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateCoverImage()` */
  static readonly StylistProfileManagementUpdateCoverImagePath = '/api/customer/StylistProfileManagement/UpdateCoverImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateCoverImage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateCoverImage$Response(params?: StylistProfileManagementUpdateCoverImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateCoverImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateCoverImage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateCoverImage(params?: StylistProfileManagementUpdateCoverImage$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateCoverImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateStylistInformation()` */
  static readonly StylistProfileManagementUpdateStylistInformationPath = '/api/customer/StylistProfileManagement/UpdateStylistInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistInformation$Response(params?: StylistProfileManagementUpdateStylistInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateStylistInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistInformation(params?: StylistProfileManagementUpdateStylistInformation$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateStylistInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistProfile()` */
  static readonly StylistProfileManagementGetStylistProfilePath = '/api/customer/StylistProfileManagement/GetStylistProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Plain$Response(params?: StylistProfileManagementGetStylistProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistProfileDto>> {
    return stylistProfileManagementGetStylistProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Plain(params?: StylistProfileManagementGetStylistProfile$Plain$Params, context?: HttpContext): Observable<OutputGetStylistProfileDto> {
    return this.stylistProfileManagementGetStylistProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistProfileDto>): OutputGetStylistProfileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Response(params?: StylistProfileManagementGetStylistProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistProfileDto>> {
    return stylistProfileManagementGetStylistProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile(params?: StylistProfileManagementGetStylistProfile$Params, context?: HttpContext): Observable<OutputGetStylistProfileDto> {
    return this.stylistProfileManagementGetStylistProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistProfileDto>): OutputGetStylistProfileDto => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCreateStylistWork()` */
  static readonly StylistProfileManagementCreateStylistWorkPath = '/api/customer/StylistProfileManagement/CreateStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWork$Response(params?: StylistProfileManagementCreateStylistWork$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementCreateStylistWork(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWork(params?: StylistProfileManagementCreateStylistWork$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementCreateStylistWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCreateStylistDraft()` */
  static readonly StylistProfileManagementCreateStylistDraftPath = '/api/customer/StylistProfileManagement/CreateStylistDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistDraft()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistDraft$Response(params?: StylistProfileManagementCreateStylistDraft$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementCreateStylistDraft(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistDraft$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistDraft(params?: StylistProfileManagementCreateStylistDraft$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementCreateStylistDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementDeleteStylistWork()` */
  static readonly StylistProfileManagementDeleteStylistWorkPath = '/api/customer/StylistProfileManagement/DeleteStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementDeleteStylistWork$Response(params?: StylistProfileManagementDeleteStylistWork$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementDeleteStylistWork(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementDeleteStylistWork(params?: StylistProfileManagementDeleteStylistWork$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementDeleteStylistWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateStylistWork()` */
  static readonly StylistProfileManagementUpdateStylistWorkPath = '/api/customer/StylistProfileManagement/UpdateStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistWork$Response(params?: StylistProfileManagementUpdateStylistWork$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateStylistWork(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistWork(params?: StylistProfileManagementUpdateStylistWork$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateStylistWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateDraftStatus()` */
  static readonly StylistProfileManagementUpdateDraftStatusPath = '/api/customer/StylistProfileManagement/UpdateDraftStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateDraftStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateDraftStatus$Response(params?: StylistProfileManagementUpdateDraftStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistProfileManagementUpdateDraftStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateDraftStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateDraftStatus(params?: StylistProfileManagementUpdateDraftStatus$Params, context?: HttpContext): Observable<void> {
    return this.stylistProfileManagementUpdateDraftStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistDraft()` */
  static readonly StylistProfileManagementGetStylistDraftPath = '/api/customer/StylistProfileManagement/GetStylistDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDraft$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Plain$Response(params?: StylistProfileManagementGetStylistDraft$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {
    return stylistProfileManagementGetStylistDraft$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDraft$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Plain(params?: StylistProfileManagementGetStylistDraft$Plain$Params, context?: HttpContext): Observable<Array<OutputGetStylistWorkDto>> {
    return this.stylistProfileManagementGetStylistDraft$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>): Array<OutputGetStylistWorkDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Response(params?: StylistProfileManagementGetStylistDraft$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {
    return stylistProfileManagementGetStylistDraft(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft(params?: StylistProfileManagementGetStylistDraft$Params, context?: HttpContext): Observable<Array<OutputGetStylistWorkDto>> {
    return this.stylistProfileManagementGetStylistDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>): Array<OutputGetStylistWorkDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetOneStylistWork()` */
  static readonly StylistProfileManagementGetOneStylistWorkPath = '/api/customer/StylistProfileManagement/GetOneStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetOneStylistWork$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Plain$Response(params?: StylistProfileManagementGetOneStylistWork$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {
    return stylistProfileManagementGetOneStylistWork$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetOneStylistWork$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Plain(params?: StylistProfileManagementGetOneStylistWork$Plain$Params, context?: HttpContext): Observable<OutputGetStylistWorkDto> {
    return this.stylistProfileManagementGetOneStylistWork$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>): OutputGetStylistWorkDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetOneStylistWork()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Response(params?: StylistProfileManagementGetOneStylistWork$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {
    return stylistProfileManagementGetOneStylistWork(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetOneStylistWork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork(params?: StylistProfileManagementGetOneStylistWork$Params, context?: HttpContext): Observable<OutputGetStylistWorkDto> {
    return this.stylistProfileManagementGetOneStylistWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>): OutputGetStylistWorkDto => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllStylistWork()` */
  static readonly StylistProfileManagementGetAllStylistWorkPath = '/api/customer/StylistProfileManagement/GetAllStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWork$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Plain$Response(params?: StylistProfileManagementGetAllStylistWork$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {
    return stylistProfileManagementGetAllStylistWork$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWork$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Plain(params?: StylistProfileManagementGetAllStylistWork$Plain$Params, context?: HttpContext): Observable<Array<OutputGetStylistWorkDto>> {
    return this.stylistProfileManagementGetAllStylistWork$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>): Array<OutputGetStylistWorkDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWork()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Response(params?: StylistProfileManagementGetAllStylistWork$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {
    return stylistProfileManagementGetAllStylistWork(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork(params?: StylistProfileManagementGetAllStylistWork$Params, context?: HttpContext): Observable<Array<OutputGetStylistWorkDto>> {
    return this.stylistProfileManagementGetAllStylistWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>): Array<OutputGetStylistWorkDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllStyle()` */
  static readonly StylistProfileManagementGetAllStylePath = '/api/customer/StylistProfileManagement/GetAllStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStyle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Plain$Response(params?: StylistProfileManagementGetAllStyle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {
    return stylistProfileManagementGetAllStyle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStyle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Plain(params?: StylistProfileManagementGetAllStyle$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylesDto>> {
    return this.stylistProfileManagementGetAllStyle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>): Array<OutputGetAllStylesDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStyle()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Response(params?: StylistProfileManagementGetAllStyle$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {
    return stylistProfileManagementGetAllStyle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStyle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle(params?: StylistProfileManagementGetAllStyle$Params, context?: HttpContext): Observable<Array<OutputGetAllStylesDto>> {
    return this.stylistProfileManagementGetAllStyle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>): Array<OutputGetAllStylesDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllAge()` */
  static readonly StylistProfileManagementGetAllAgePath = '/api/customer/StylistProfileManagement/GetAllAge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllAge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Plain$Response(params?: StylistProfileManagementGetAllAge$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllAgeDto>>> {
    return stylistProfileManagementGetAllAge$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllAge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Plain(params?: StylistProfileManagementGetAllAge$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllAgeDto>> {
    return this.stylistProfileManagementGetAllAge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllAgeDto>>): Array<OutputGetAllAgeDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllAge()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Response(params?: StylistProfileManagementGetAllAge$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllAgeDto>>> {
    return stylistProfileManagementGetAllAge(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllAge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge(params?: StylistProfileManagementGetAllAge$Params, context?: HttpContext): Observable<Array<OutputGetAllAgeDto>> {
    return this.stylistProfileManagementGetAllAge$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllAgeDto>>): Array<OutputGetAllAgeDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCreateStylistCollection()` */
  static readonly StylistProfileManagementCreateStylistCollectionPath = '/api/customer/StylistProfileManagement/CreateStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistCollection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Plain$Response(params?: StylistProfileManagementCreateStylistCollection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistCollection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistCollection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Plain(params?: StylistProfileManagementCreateStylistCollection$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistCollection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistCollection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Response(params?: StylistProfileManagementCreateStylistCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistCollection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection(params?: StylistProfileManagementCreateStylistCollection$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementDeleteStylistCollection()` */
  static readonly StylistProfileManagementDeleteStylistCollectionPath = '/api/customer/StylistProfileManagement/DeleteStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Plain$Response(params?: StylistProfileManagementDeleteStylistCollection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementDeleteStylistCollection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Plain(params?: StylistProfileManagementDeleteStylistCollection$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementDeleteStylistCollection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Response(params?: StylistProfileManagementDeleteStylistCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementDeleteStylistCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection(params?: StylistProfileManagementDeleteStylistCollection$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementDeleteStylistCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementUpdateStylistCollectionPendingType()` */
  static readonly StylistProfileManagementUpdateStylistCollectionPendingTypePath = '/api/customer/StylistProfileManagement/UpdateStylistCollectionPendingType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistCollectionPendingType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response(params?: StylistProfileManagementUpdateStylistCollectionPendingType$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementUpdateStylistCollectionPendingType$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Plain(params?: StylistProfileManagementUpdateStylistCollectionPendingType$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistCollectionPendingType()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Response(params?: StylistProfileManagementUpdateStylistCollectionPendingType$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementUpdateStylistCollectionPendingType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistCollectionPendingType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType(params?: StylistProfileManagementUpdateStylistCollectionPendingType$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementUpdateStylistCollectionPendingType$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllStylistCollection()` */
  static readonly StylistProfileManagementGetAllStylistCollectionPath = '/api/customer/StylistProfileManagement/GetAllStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Plain$Response(params?: StylistProfileManagementGetAllStylistCollection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {
    return stylistProfileManagementGetAllStylistCollection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Plain(params?: StylistProfileManagementGetAllStylistCollection$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistCollectionDto>> {
    return this.stylistProfileManagementGetAllStylistCollection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>): Array<OutputGetAllStylistCollectionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Response(params?: StylistProfileManagementGetAllStylistCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {
    return stylistProfileManagementGetAllStylistCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection(params?: StylistProfileManagementGetAllStylistCollection$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistCollectionDto>> {
    return this.stylistProfileManagementGetAllStylistCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>): Array<OutputGetAllStylistCollectionDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllStylistPendingCollection()` */
  static readonly StylistProfileManagementGetAllStylistPendingCollectionPath = '/api/customer/StylistProfileManagement/GetAllStylistPendingCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistPendingCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Plain$Response(params?: StylistProfileManagementGetAllStylistPendingCollection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {
    return stylistProfileManagementGetAllStylistPendingCollection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistPendingCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Plain(params?: StylistProfileManagementGetAllStylistPendingCollection$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistCollectionDto>> {
    return this.stylistProfileManagementGetAllStylistPendingCollection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>): Array<OutputGetAllStylistCollectionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistPendingCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Response(params?: StylistProfileManagementGetAllStylistPendingCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {
    return stylistProfileManagementGetAllStylistPendingCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistPendingCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection(params?: StylistProfileManagementGetAllStylistPendingCollection$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistCollectionDto>> {
    return this.stylistProfileManagementGetAllStylistPendingCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>): Array<OutputGetAllStylistCollectionDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCreateStylistBankAccount()` */
  static readonly StylistProfileManagementCreateStylistBankAccountPath = '/api/customer/StylistProfileManagement/CreateStylistBankAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistBankAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Plain$Response(params?: StylistProfileManagementCreateStylistBankAccount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistBankAccount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistBankAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Plain(params?: StylistProfileManagementCreateStylistBankAccount$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistBankAccount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistBankAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Response(params?: StylistProfileManagementCreateStylistBankAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistBankAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistBankAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount(params?: StylistProfileManagementCreateStylistBankAccount$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistBankAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistBankAccount()` */
  static readonly StylistProfileManagementGetStylistBankAccountPath = '/api/customer/StylistProfileManagement/GetStylistBankAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistBankAccount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Plain$Response(params?: StylistProfileManagementGetStylistBankAccount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>> {
    return stylistProfileManagementGetStylistBankAccount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistBankAccount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Plain(params?: StylistProfileManagementGetStylistBankAccount$Plain$Params, context?: HttpContext): Observable<Array<OutputGetStylistBankAccountDto>> {
    return this.stylistProfileManagementGetStylistBankAccount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>): Array<OutputGetStylistBankAccountDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistBankAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Response(params?: StylistProfileManagementGetStylistBankAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>> {
    return stylistProfileManagementGetStylistBankAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistBankAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount(params?: StylistProfileManagementGetStylistBankAccount$Params, context?: HttpContext): Observable<Array<OutputGetStylistBankAccountDto>> {
    return this.stylistProfileManagementGetStylistBankAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>): Array<OutputGetStylistBankAccountDto> => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementCreateStylistWiithdrawal()` */
  static readonly StylistProfileManagementCreateStylistWiithdrawalPath = '/api/customer/StylistProfileManagement/CreateStylistWiithdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWiithdrawal$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Plain$Response(params?: StylistProfileManagementCreateStylistWiithdrawal$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistWiithdrawal$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWiithdrawal$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Plain(params?: StylistProfileManagementCreateStylistWiithdrawal$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistWiithdrawal$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWiithdrawal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Response(params?: StylistProfileManagementCreateStylistWiithdrawal$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistProfileManagementCreateStylistWiithdrawal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWiithdrawal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal(params?: StylistProfileManagementCreateStylistWiithdrawal$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistProfileManagementCreateStylistWiithdrawal$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistDashboard()` */
  static readonly StylistProfileManagementGetStylistDashboardPath = '/api/customer/StylistProfileManagement/GetStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Plain$Response(params?: StylistProfileManagementGetStylistDashboard$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StylistDashboardDto>> {
    return stylistProfileManagementGetStylistDashboard$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Plain(params?: StylistProfileManagementGetStylistDashboard$Plain$Params, context?: HttpContext): Observable<StylistDashboardDto> {
    return this.stylistProfileManagementGetStylistDashboard$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>): StylistDashboardDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Response(params?: StylistProfileManagementGetStylistDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<StylistDashboardDto>> {
    return stylistProfileManagementGetStylistDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard(params?: StylistProfileManagementGetStylistDashboard$Params, context?: HttpContext): Observable<StylistDashboardDto> {
    return this.stylistProfileManagementGetStylistDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>): StylistDashboardDto => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetStylistEarning()` */
  static readonly StylistProfileManagementGetStylistEarningPath = '/api/customer/StylistProfileManagement/GetStylistEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Plain$Response(params?: StylistProfileManagementGetStylistEarning$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputStylistEarning>> {
    return stylistProfileManagementGetStylistEarning$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Plain(params?: StylistProfileManagementGetStylistEarning$Plain$Params, context?: HttpContext): Observable<OutputStylistEarning> {
    return this.stylistProfileManagementGetStylistEarning$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputStylistEarning>): OutputStylistEarning => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Response(params?: StylistProfileManagementGetStylistEarning$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputStylistEarning>> {
    return stylistProfileManagementGetStylistEarning(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning(params?: StylistProfileManagementGetStylistEarning$Params, context?: HttpContext): Observable<OutputStylistEarning> {
    return this.stylistProfileManagementGetStylistEarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputStylistEarning>): OutputStylistEarning => r.body)
    );
  }

  /** Path part for operation `stylistProfileManagementGetAllStylistWithdrawal()` */
  static readonly StylistProfileManagementGetAllStylistWithdrawalPath = '/api/customer/StylistProfileManagement/GetAllStylistWithdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWithdrawal$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Plain$Response(params?: StylistProfileManagementGetAllStylistWithdrawal$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>> {
    return stylistProfileManagementGetAllStylistWithdrawal$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWithdrawal$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Plain(params?: StylistProfileManagementGetAllStylistWithdrawal$Plain$Params, context?: HttpContext): Observable<Array<GetAllStylistWithdrawalDto>> {
    return this.stylistProfileManagementGetAllStylistWithdrawal$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>): Array<GetAllStylistWithdrawalDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWithdrawal()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Response(params?: StylistProfileManagementGetAllStylistWithdrawal$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>> {
    return stylistProfileManagementGetAllStylistWithdrawal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWithdrawal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal(params?: StylistProfileManagementGetAllStylistWithdrawal$Params, context?: HttpContext): Observable<Array<GetAllStylistWithdrawalDto>> {
    return this.stylistProfileManagementGetAllStylistWithdrawal$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>): Array<GetAllStylistWithdrawalDto> => r.body)
    );
  }

}
