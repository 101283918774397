<mat-select
  id="{{ id }}"
  [disabled]="disabled"
  [disableRipple]="true"
  [placeholder]="placeholder"
  [value]="value"
  (selectionChange)="selectItem($event)"
>
  <ng-container *ngIf="separateOption">
    <mat-option [value]="separateOption">{{ separateOption }}</mat-option>
    <hr />
  </ng-container>

  <mat-option *ngFor="let menuOption of menuList" [value]="menuOption.value">{{ menuOption.name }}</mat-option>
</mat-select>
