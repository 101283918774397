import { Injectable } from "@angular/core";

import jwt_decode from "jwt-decode";

import {
  InputRefreshTokenDto,
  InputSignInUserDto,
  OutputAllMenuPagesPermissionDto,
  UserAccountService,
  UserManagementService,
} from "./api";
import { Router } from "@angular/router";

export const TOKEN_NAME = "token";
export const REFRESH_TOKEN_NAME = "refreshToken";
@Injectable()
export class AuthService {
  constructor(
    private userAccountService: UserAccountService,

    private userManagementService: UserManagementService,
    private router: Router
  ) {}

  currentPage = 0;
  isLogin(): boolean {
    if (this.getToken() !== null && !this.isTokenExpired(this.getToken())) {
      this.setMenuPagePermission();
      return true;
    } else {
      return false;
    }
  }
  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }
  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token) as any;

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  getRoleId() {
    const decoded = jwt_decode(this.getToken()) as any;

    if (decoded.RoleId === undefined) {
      return null;
    }

    return decoded.RoleId;
  }
  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  login(inputSignInUserDto: InputSignInUserDto): Promise<boolean> {
    return this.userAccountService
      .userAccountSignIn({ body: inputSignInUserDto })
      .toPromise()
      .then(
        (value) => {
          this.setToken(value.token);
          this.setRefreshToken(value.refreshToken);
          this.refreshToken(value.token, value.refreshToken);

          return true;
        },
        (error) => {
          return false;
        }
      );
  }

  setMenuPagePermission(): Promise<any> {
    return this.userManagementService
      .userManagementGetMenusPagesPermissionByRoleId({
        roleId: +this.getRoleId(),
      })
      .toPromise()
      .then((value) =>
        localStorage.setItem("Permission", JSON.stringify(value))
      )
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        this.router.navigate(["/Login"]);
      });
  }

  getMenuPagePermission(): OutputAllMenuPagesPermissionDto[] {
    return JSON.parse(
      localStorage.getItem("Permission").toString()
    ) as OutputAllMenuPagesPermissionDto[];
  }
  refreshToken(token, refreshToken) {
    const millisTill10 =
      new Date(this.getTokenExpirationDate(token)).getTime() -
      new Date().getTime();

    const time = setTimeout(() => {
      this.userAccountService
        .userAccountToken({
          body: {
            token,
            refreshToken,
          } as InputRefreshTokenDto,
        })
        .subscribe(
          (value) => {
            this.setRefreshToken(value.refreshToken);
            this.setToken(value.token);
            this.setRefreshToken(value.refreshToken);
            this.refreshToken(value.token, value.refreshToken);
          },
          (error) => {
            clearTimeout(time);
          }
        );
    }, millisTill10);
  }
  refreshCurrentToken(): Promise<boolean> {
    if (this.getToken() == null) {
      return new Promise((value) => value(false));
    }
    return this.userAccountService
      .userAccountToken({
        body: {
          token: this.getToken(),
          refreshToken: this.getRefreshToken(),
        } as InputRefreshTokenDto,
      })
      .toPromise()
      .then(
        (value) => {
          this.setRefreshToken(value.refreshToken);
          this.setToken(value.token);
          this.refreshToken(value.token, value.refreshToken);
          return true;
        },
        (error) => {
          return false;
        }
      );
  }
}
