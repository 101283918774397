<span id="error-validation" *ngIf="control.touched && errors && errors[0]"></span>

<label class="size-bold">{{ text + (isRequired ? '*' : '') }}</label>

<p-dropdown
  autoWidth="false"
  [style]="{ width: '100%' }"
  placeholder="{{ text + (isRequired ? '*' : '') }}"
  (focusout)="touch()"
  (onChange)="onChange($event, selectedItems)"
  #input
  [(ngModel)]="selectedItems"
  *ngIf="filter"
  [options]="filter"
  class="color-droupdown"
  [filter]="true"
  filterBy="label"
>
  <ng-template let-country pTemplate="item">
    <div *ngIf="country.value !== filter[filter.length - 1].value || !allowAddingNewItem" class="country-item">
      <div class="d-flex">
        <img *ngIf="hasImage" width="100px" src="{{ country.image }}" />
        <div *ngIf="hasColorBox" style="height: 30px; width: 30px" [style.background-color]="country.colorCode"></div>
        <span
          *ngIf="hasImage || hasColorBox"
          style="margin-top: auto; margin-bottom: auto; margin-left: 15px; margin-right: 15px"
        >
          {{ country.label }}</span
        >
        <span *ngIf="!hasImage && !hasColorBox"> {{ country.label }}</span>
        <!-- <span  > <img (click)="deleteItem(1)" src="assets/img/delete_icn.png"> </span> -->
      </div>
    </div>
    <div *ngIf="country.value === filter[filter.length - 1].value && allowAddingNewItem" class="country-item">
      <div>
        <span class="pr-2"><img src="assets/img/Plus.png" /></span> <span>Add new item </span>
      </div>
    </div>
  </ng-template>

  <ng-template let-country pTemplate="selectedItem">
    <div
      *ngIf="hasColorBox"
      [style.background]="country.colorCode"
      style="height: 100%; text-align: center; display: flex"
    >
      <span class="sss" *ngIf="hasImage || hasColorBox" style="background-color: aliceblue; margin: auto; padding: 2px">
        {{ country.label }}</span
      >
    </div>
  </ng-template>
</p-dropdown>

<div class="d-content" *ngIf="control.invalid && control.touched" style="height: 11px" class="text-danger">
  <p style="text-align: initial" class="text-danger" *ngFor="let error of errors">{{ error }}</p>
</div>
