/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { offerManagementCreateBundle } from '../fn/offer-management/offer-management-create-bundle';
import { OfferManagementCreateBundle$Params } from '../fn/offer-management/offer-management-create-bundle';
import { offerManagementCreateBundle$Plain } from '../fn/offer-management/offer-management-create-bundle-plain';
import { OfferManagementCreateBundle$Plain$Params } from '../fn/offer-management/offer-management-create-bundle-plain';
import { offerManagementCreateOffer } from '../fn/offer-management/offer-management-create-offer';
import { OfferManagementCreateOffer$Params } from '../fn/offer-management/offer-management-create-offer';
import { offerManagementCreateOffer$Plain } from '../fn/offer-management/offer-management-create-offer-plain';
import { OfferManagementCreateOffer$Plain$Params } from '../fn/offer-management/offer-management-create-offer-plain';
import { offerManagementCreateTags } from '../fn/offer-management/offer-management-create-tags';
import { OfferManagementCreateTags$Params } from '../fn/offer-management/offer-management-create-tags';
import { offerManagementCreateTags$Plain } from '../fn/offer-management/offer-management-create-tags-plain';
import { OfferManagementCreateTags$Plain$Params } from '../fn/offer-management/offer-management-create-tags-plain';
import { offerManagementDeleteBundle } from '../fn/offer-management/offer-management-delete-bundle';
import { OfferManagementDeleteBundle$Params } from '../fn/offer-management/offer-management-delete-bundle';
import { offerManagementDeleteBundle$Plain } from '../fn/offer-management/offer-management-delete-bundle-plain';
import { OfferManagementDeleteBundle$Plain$Params } from '../fn/offer-management/offer-management-delete-bundle-plain';
import { offerManagementDeleteOffer } from '../fn/offer-management/offer-management-delete-offer';
import { OfferManagementDeleteOffer$Params } from '../fn/offer-management/offer-management-delete-offer';
import { offerManagementDeleteOffer$Plain } from '../fn/offer-management/offer-management-delete-offer-plain';
import { OfferManagementDeleteOffer$Plain$Params } from '../fn/offer-management/offer-management-delete-offer-plain';
import { offerManagementDeleteTags } from '../fn/offer-management/offer-management-delete-tags';
import { OfferManagementDeleteTags$Params } from '../fn/offer-management/offer-management-delete-tags';
import { offerManagementDeleteTags$Plain } from '../fn/offer-management/offer-management-delete-tags-plain';
import { OfferManagementDeleteTags$Plain$Params } from '../fn/offer-management/offer-management-delete-tags-plain';
import { offerManagementGetAllBundles } from '../fn/offer-management/offer-management-get-all-bundles';
import { OfferManagementGetAllBundles$Params } from '../fn/offer-management/offer-management-get-all-bundles';
import { offerManagementGetAllBundles$Plain } from '../fn/offer-management/offer-management-get-all-bundles-plain';
import { OfferManagementGetAllBundles$Plain$Params } from '../fn/offer-management/offer-management-get-all-bundles-plain';
import { offerManagementGetAllBundleWithItem } from '../fn/offer-management/offer-management-get-all-bundle-with-item';
import { OfferManagementGetAllBundleWithItem$Params } from '../fn/offer-management/offer-management-get-all-bundle-with-item';
import { offerManagementGetAllBundleWithItem$Plain } from '../fn/offer-management/offer-management-get-all-bundle-with-item-plain';
import { OfferManagementGetAllBundleWithItem$Plain$Params } from '../fn/offer-management/offer-management-get-all-bundle-with-item-plain';
import { offerManagementGetAllOffers } from '../fn/offer-management/offer-management-get-all-offers';
import { OfferManagementGetAllOffers$Params } from '../fn/offer-management/offer-management-get-all-offers';
import { offerManagementGetAllOffers$Plain } from '../fn/offer-management/offer-management-get-all-offers-plain';
import { OfferManagementGetAllOffers$Plain$Params } from '../fn/offer-management/offer-management-get-all-offers-plain';
import { offerManagementGetAllTags } from '../fn/offer-management/offer-management-get-all-tags';
import { OfferManagementGetAllTags$Params } from '../fn/offer-management/offer-management-get-all-tags';
import { offerManagementGetAllTags$Plain } from '../fn/offer-management/offer-management-get-all-tags-plain';
import { OfferManagementGetAllTags$Plain$Params } from '../fn/offer-management/offer-management-get-all-tags-plain';
import { offerManagementUpdateBundle } from '../fn/offer-management/offer-management-update-bundle';
import { OfferManagementUpdateBundle$Params } from '../fn/offer-management/offer-management-update-bundle';
import { offerManagementUpdateBundle$Plain } from '../fn/offer-management/offer-management-update-bundle-plain';
import { OfferManagementUpdateBundle$Plain$Params } from '../fn/offer-management/offer-management-update-bundle-plain';
import { offerManagementUpdateOffer } from '../fn/offer-management/offer-management-update-offer';
import { OfferManagementUpdateOffer$Params } from '../fn/offer-management/offer-management-update-offer';
import { offerManagementUpdateOffer$Plain } from '../fn/offer-management/offer-management-update-offer-plain';
import { OfferManagementUpdateOffer$Plain$Params } from '../fn/offer-management/offer-management-update-offer-plain';
import { offerManagementUpdateTags } from '../fn/offer-management/offer-management-update-tags';
import { OfferManagementUpdateTags$Params } from '../fn/offer-management/offer-management-update-tags';
import { offerManagementUpdateTags$Plain } from '../fn/offer-management/offer-management-update-tags-plain';
import { OfferManagementUpdateTags$Plain$Params } from '../fn/offer-management/offer-management-update-tags-plain';
import { OutputGetAllBundleDto } from '../models/output-get-all-bundle-dto';
import { OutputGetAllOfferDto } from '../models/output-get-all-offer-dto';
import { OutputGetAllTagsDto } from '../models/output-get-all-tags-dto';
import { OutputGetBundleDetailDto } from '../models/output-get-bundle-detail-dto';

@Injectable({ providedIn: 'root' })
export class OfferManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `offerManagementGetAllBundleWithItem()` */
  static readonly OfferManagementGetAllBundleWithItemPath = '/api/admin/OfferManagement/GetAllBundleWithItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundleWithItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Plain$Response(params?: OfferManagementGetAllBundleWithItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {
    return offerManagementGetAllBundleWithItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundleWithItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Plain(params?: OfferManagementGetAllBundleWithItem$Plain$Params, context?: HttpContext): Observable<Array<OutputGetBundleDetailDto>> {
    return this.offerManagementGetAllBundleWithItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>): Array<OutputGetBundleDetailDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundleWithItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Response(params?: OfferManagementGetAllBundleWithItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {
    return offerManagementGetAllBundleWithItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundleWithItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem(params?: OfferManagementGetAllBundleWithItem$Params, context?: HttpContext): Observable<Array<OutputGetBundleDetailDto>> {
    return this.offerManagementGetAllBundleWithItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>): Array<OutputGetBundleDetailDto> => r.body)
    );
  }

  /** Path part for operation `offerManagementCreateBundle()` */
  static readonly OfferManagementCreateBundlePath = '/api/admin/OfferManagement/CreateBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Plain$Response(params?: OfferManagementCreateBundle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateBundle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Plain(params?: OfferManagementCreateBundle$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateBundle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Response(params?: OfferManagementCreateBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle(params?: OfferManagementCreateBundle$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementUpdateBundle()` */
  static readonly OfferManagementUpdateBundlePath = '/api/admin/OfferManagement/UpdateBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Plain$Response(params?: OfferManagementUpdateBundle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateBundle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Plain(params?: OfferManagementUpdateBundle$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateBundle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Response(params?: OfferManagementUpdateBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle(params?: OfferManagementUpdateBundle$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementGetAllBundles()` */
  static readonly OfferManagementGetAllBundlesPath = '/api/admin/OfferManagement/GetAllBundles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Plain$Response(params?: OfferManagementGetAllBundles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {
    return offerManagementGetAllBundles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Plain(params?: OfferManagementGetAllBundles$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBundleDto>> {
    return this.offerManagementGetAllBundles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>): Array<OutputGetAllBundleDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Response(params?: OfferManagementGetAllBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {
    return offerManagementGetAllBundles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles(params?: OfferManagementGetAllBundles$Params, context?: HttpContext): Observable<Array<OutputGetAllBundleDto>> {
    return this.offerManagementGetAllBundles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>): Array<OutputGetAllBundleDto> => r.body)
    );
  }

  /** Path part for operation `offerManagementDeleteBundle()` */
  static readonly OfferManagementDeleteBundlePath = '/api/admin/OfferManagement/DeleteBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Plain$Response(params?: OfferManagementDeleteBundle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteBundle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Plain(params?: OfferManagementDeleteBundle$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteBundle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Response(params?: OfferManagementDeleteBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle(params?: OfferManagementDeleteBundle$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementCreateOffer()` */
  static readonly OfferManagementCreateOfferPath = '/api/admin/OfferManagement/CreateOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Plain$Response(params?: OfferManagementCreateOffer$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateOffer$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Plain(params?: OfferManagementCreateOffer$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateOffer$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Response(params?: OfferManagementCreateOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer(params?: OfferManagementCreateOffer$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementUpdateOffer()` */
  static readonly OfferManagementUpdateOfferPath = '/api/admin/OfferManagement/UpdateOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Plain$Response(params?: OfferManagementUpdateOffer$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateOffer$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Plain(params?: OfferManagementUpdateOffer$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateOffer$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Response(params?: OfferManagementUpdateOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer(params?: OfferManagementUpdateOffer$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementGetAllOffers()` */
  static readonly OfferManagementGetAllOffersPath = '/api/admin/OfferManagement/GetAllOffers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllOffers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Plain$Response(params?: OfferManagementGetAllOffers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {
    return offerManagementGetAllOffers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllOffers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Plain(params?: OfferManagementGetAllOffers$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllOfferDto>> {
    return this.offerManagementGetAllOffers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>): Array<OutputGetAllOfferDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Response(params?: OfferManagementGetAllOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {
    return offerManagementGetAllOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers(params?: OfferManagementGetAllOffers$Params, context?: HttpContext): Observable<Array<OutputGetAllOfferDto>> {
    return this.offerManagementGetAllOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>): Array<OutputGetAllOfferDto> => r.body)
    );
  }

  /** Path part for operation `offerManagementDeleteOffer()` */
  static readonly OfferManagementDeleteOfferPath = '/api/admin/OfferManagement/DeleteOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Plain$Response(params?: OfferManagementDeleteOffer$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteOffer$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Plain(params?: OfferManagementDeleteOffer$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteOffer$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Response(params?: OfferManagementDeleteOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer(params?: OfferManagementDeleteOffer$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementCreateTags()` */
  static readonly OfferManagementCreateTagsPath = '/api/admin/OfferManagement/CreateTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Plain$Response(params?: OfferManagementCreateTags$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateTags$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Plain(params?: OfferManagementCreateTags$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateTags$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Response(params?: OfferManagementCreateTags$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementCreateTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags(params?: OfferManagementCreateTags$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementCreateTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementDeleteTags()` */
  static readonly OfferManagementDeleteTagsPath = '/api/admin/OfferManagement/DeleteTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Plain$Response(params?: OfferManagementDeleteTags$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteTags$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Plain(params?: OfferManagementDeleteTags$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteTags$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Response(params?: OfferManagementDeleteTags$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementDeleteTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags(params?: OfferManagementDeleteTags$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementDeleteTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementUpdateTags()` */
  static readonly OfferManagementUpdateTagsPath = '/api/admin/OfferManagement/UpdateTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Plain$Response(params?: OfferManagementUpdateTags$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateTags$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Plain(params?: OfferManagementUpdateTags$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateTags$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Response(params?: OfferManagementUpdateTags$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offerManagementUpdateTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags(params?: OfferManagementUpdateTags$Params, context?: HttpContext): Observable<boolean> {
    return this.offerManagementUpdateTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offerManagementGetAllTags()` */
  static readonly OfferManagementGetAllTagsPath = '/api/admin/OfferManagement/GetAllTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllTags$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Plain$Response(params?: OfferManagementGetAllTags$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {
    return offerManagementGetAllTags$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllTags$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Plain(params?: OfferManagementGetAllTags$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllTagsDto>> {
    return this.offerManagementGetAllTags$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>): Array<OutputGetAllTagsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Response(params?: OfferManagementGetAllTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {
    return offerManagementGetAllTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags(params?: OfferManagementGetAllTags$Params, context?: HttpContext): Observable<Array<OutputGetAllTagsDto>> {
    return this.offerManagementGetAllTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>): Array<OutputGetAllTagsDto> => r.body)
    );
  }

}
