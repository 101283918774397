import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appLettersOnly]'
})
export class LettersOnlyDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Check if the key is an Arabic or English letter, space, or newline
    if (!/[a-zA-Z\u0621-\u064A \n]/.test(event.key) && !this.isControlKey(event)) {
      event.preventDefault();  // Prevent the addition of any non-letter characters
    } else if (event.shiftKey && this.isPunctuationKey(event.key)) {
      event.preventDefault();  // Prevent punctuation when "Shift" is held down
    }
  }

  private isControlKey(event: KeyboardEvent): boolean {
    // Allow control actions like arrow movements, backspace, etc.
    const controlKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Enter', 'Escape'];
    return controlKeys.includes(event.key) || event.ctrlKey || event.metaKey;
  }

  private isPunctuationKey(key: string): boolean {
    // Regex to match typical punctuation characters that might be allowed with shift
    return /[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(key);
  }
}
