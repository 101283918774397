/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputGetAllBranchDto } from '../models/output-get-all-branch-dto';
import { OutputGetAllCityDto } from '../models/output-get-all-city-dto';
import { OutputGetAllCountryDto } from '../models/output-get-all-country-dto';
import { OutputGetAllGovernorateDto } from '../models/output-get-all-governorate-dto';
import { placeManagementCreateBranch } from '../fn/place-management/place-management-create-branch';
import { PlaceManagementCreateBranch$Params } from '../fn/place-management/place-management-create-branch';
import { placeManagementCreateBranch$Plain } from '../fn/place-management/place-management-create-branch-plain';
import { PlaceManagementCreateBranch$Plain$Params } from '../fn/place-management/place-management-create-branch-plain';
import { placeManagementCreateCity } from '../fn/place-management/place-management-create-city';
import { PlaceManagementCreateCity$Params } from '../fn/place-management/place-management-create-city';
import { placeManagementCreateCity$Plain } from '../fn/place-management/place-management-create-city-plain';
import { PlaceManagementCreateCity$Plain$Params } from '../fn/place-management/place-management-create-city-plain';
import { placeManagementCreateCountry } from '../fn/place-management/place-management-create-country';
import { PlaceManagementCreateCountry$Params } from '../fn/place-management/place-management-create-country';
import { placeManagementCreateCountry$Plain } from '../fn/place-management/place-management-create-country-plain';
import { PlaceManagementCreateCountry$Plain$Params } from '../fn/place-management/place-management-create-country-plain';
import { placeManagementCreateGovernorate } from '../fn/place-management/place-management-create-governorate';
import { PlaceManagementCreateGovernorate$Params } from '../fn/place-management/place-management-create-governorate';
import { placeManagementCreateGovernorate$Plain } from '../fn/place-management/place-management-create-governorate-plain';
import { PlaceManagementCreateGovernorate$Plain$Params } from '../fn/place-management/place-management-create-governorate-plain';
import { placeManagementDeleteBranch } from '../fn/place-management/place-management-delete-branch';
import { PlaceManagementDeleteBranch$Params } from '../fn/place-management/place-management-delete-branch';
import { placeManagementDeleteBranch$Plain } from '../fn/place-management/place-management-delete-branch-plain';
import { PlaceManagementDeleteBranch$Plain$Params } from '../fn/place-management/place-management-delete-branch-plain';
import { placeManagementDeleteCity } from '../fn/place-management/place-management-delete-city';
import { PlaceManagementDeleteCity$Params } from '../fn/place-management/place-management-delete-city';
import { placeManagementDeleteCity$Plain } from '../fn/place-management/place-management-delete-city-plain';
import { PlaceManagementDeleteCity$Plain$Params } from '../fn/place-management/place-management-delete-city-plain';
import { placeManagementDeleteCountry } from '../fn/place-management/place-management-delete-country';
import { PlaceManagementDeleteCountry$Params } from '../fn/place-management/place-management-delete-country';
import { placeManagementDeleteCountry$Plain } from '../fn/place-management/place-management-delete-country-plain';
import { PlaceManagementDeleteCountry$Plain$Params } from '../fn/place-management/place-management-delete-country-plain';
import { placeManagementDeleteGovernorate } from '../fn/place-management/place-management-delete-governorate';
import { PlaceManagementDeleteGovernorate$Params } from '../fn/place-management/place-management-delete-governorate';
import { placeManagementDeleteGovernorate$Plain } from '../fn/place-management/place-management-delete-governorate-plain';
import { PlaceManagementDeleteGovernorate$Plain$Params } from '../fn/place-management/place-management-delete-governorate-plain';
import { placeManagementGetAllBranch } from '../fn/place-management/place-management-get-all-branch';
import { PlaceManagementGetAllBranch$Params } from '../fn/place-management/place-management-get-all-branch';
import { placeManagementGetAllBranch$Plain } from '../fn/place-management/place-management-get-all-branch-plain';
import { PlaceManagementGetAllBranch$Plain$Params } from '../fn/place-management/place-management-get-all-branch-plain';
import { placeManagementGetAllBranchByCityId } from '../fn/place-management/place-management-get-all-branch-by-city-id';
import { PlaceManagementGetAllBranchByCityId$Params } from '../fn/place-management/place-management-get-all-branch-by-city-id';
import { placeManagementGetAllBranchByCityId$Plain } from '../fn/place-management/place-management-get-all-branch-by-city-id-plain';
import { PlaceManagementGetAllBranchByCityId$Plain$Params } from '../fn/place-management/place-management-get-all-branch-by-city-id-plain';
import { placeManagementGetAllCity } from '../fn/place-management/place-management-get-all-city';
import { PlaceManagementGetAllCity$Params } from '../fn/place-management/place-management-get-all-city';
import { placeManagementGetAllCity$Plain } from '../fn/place-management/place-management-get-all-city-plain';
import { PlaceManagementGetAllCity$Plain$Params } from '../fn/place-management/place-management-get-all-city-plain';
import { placeManagementGetAllCityByGovenorateId } from '../fn/place-management/place-management-get-all-city-by-govenorate-id';
import { PlaceManagementGetAllCityByGovenorateId$Params } from '../fn/place-management/place-management-get-all-city-by-govenorate-id';
import { placeManagementGetAllCityByGovenorateId$Plain } from '../fn/place-management/place-management-get-all-city-by-govenorate-id-plain';
import { PlaceManagementGetAllCityByGovenorateId$Plain$Params } from '../fn/place-management/place-management-get-all-city-by-govenorate-id-plain';
import { placeManagementGetAllCountry } from '../fn/place-management/place-management-get-all-country';
import { PlaceManagementGetAllCountry$Params } from '../fn/place-management/place-management-get-all-country';
import { placeManagementGetAllCountry$Plain } from '../fn/place-management/place-management-get-all-country-plain';
import { PlaceManagementGetAllCountry$Plain$Params } from '../fn/place-management/place-management-get-all-country-plain';
import { placeManagementGetAllGovernorate } from '../fn/place-management/place-management-get-all-governorate';
import { PlaceManagementGetAllGovernorate$Params } from '../fn/place-management/place-management-get-all-governorate';
import { placeManagementGetAllGovernorate$Plain } from '../fn/place-management/place-management-get-all-governorate-plain';
import { PlaceManagementGetAllGovernorate$Plain$Params } from '../fn/place-management/place-management-get-all-governorate-plain';
import { placeManagementUpdateBranch } from '../fn/place-management/place-management-update-branch';
import { PlaceManagementUpdateBranch$Params } from '../fn/place-management/place-management-update-branch';
import { placeManagementUpdateBranch$Plain } from '../fn/place-management/place-management-update-branch-plain';
import { PlaceManagementUpdateBranch$Plain$Params } from '../fn/place-management/place-management-update-branch-plain';
import { placeManagementUpdateCity } from '../fn/place-management/place-management-update-city';
import { PlaceManagementUpdateCity$Params } from '../fn/place-management/place-management-update-city';
import { placeManagementUpdateCity$Plain } from '../fn/place-management/place-management-update-city-plain';
import { PlaceManagementUpdateCity$Plain$Params } from '../fn/place-management/place-management-update-city-plain';
import { placeManagementUpdateCountry } from '../fn/place-management/place-management-update-country';
import { PlaceManagementUpdateCountry$Params } from '../fn/place-management/place-management-update-country';
import { placeManagementUpdateCountry$Plain } from '../fn/place-management/place-management-update-country-plain';
import { PlaceManagementUpdateCountry$Plain$Params } from '../fn/place-management/place-management-update-country-plain';
import { placeManagementUpdateGovernorate } from '../fn/place-management/place-management-update-governorate';
import { PlaceManagementUpdateGovernorate$Params } from '../fn/place-management/place-management-update-governorate';
import { placeManagementUpdateGovernorate$Plain } from '../fn/place-management/place-management-update-governorate-plain';
import { PlaceManagementUpdateGovernorate$Plain$Params } from '../fn/place-management/place-management-update-governorate-plain';

@Injectable({ providedIn: 'root' })
export class PlaceManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `placeManagementCreateBranch()` */
  static readonly PlaceManagementCreateBranchPath = '/api/admin/PlaceManagement/CreateBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Plain$Response(params?: PlaceManagementCreateBranch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateBranch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Plain(params?: PlaceManagementCreateBranch$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateBranch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Response(params?: PlaceManagementCreateBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch(params?: PlaceManagementCreateBranch$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementDeleteBranch()` */
  static readonly PlaceManagementDeleteBranchPath = '/api/admin/PlaceManagement/DeleteBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Plain$Response(params?: PlaceManagementDeleteBranch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteBranch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Plain(params?: PlaceManagementDeleteBranch$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteBranch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Response(params?: PlaceManagementDeleteBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch(params?: PlaceManagementDeleteBranch$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementUpdateBranch()` */
  static readonly PlaceManagementUpdateBranchPath = '/api/admin/PlaceManagement/UpdateBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Plain$Response(params?: PlaceManagementUpdateBranch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateBranch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Plain(params?: PlaceManagementUpdateBranch$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateBranch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Response(params?: PlaceManagementUpdateBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch(params?: PlaceManagementUpdateBranch$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllBranch()` */
  static readonly PlaceManagementGetAllBranchPath = '/api/admin/PlaceManagement/GetAllBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Plain$Response(params?: PlaceManagementGetAllBranch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {
    return placeManagementGetAllBranch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Plain(params?: PlaceManagementGetAllBranch$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBranchDto>> {
    return this.placeManagementGetAllBranch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>): Array<OutputGetAllBranchDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Response(params?: PlaceManagementGetAllBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {
    return placeManagementGetAllBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch(params?: PlaceManagementGetAllBranch$Params, context?: HttpContext): Observable<Array<OutputGetAllBranchDto>> {
    return this.placeManagementGetAllBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>): Array<OutputGetAllBranchDto> => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllBranchByCityId()` */
  static readonly PlaceManagementGetAllBranchByCityIdPath = '/api/admin/PlaceManagement/GetAllBranchByCityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranchByCityId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Plain$Response(params?: PlaceManagementGetAllBranchByCityId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {
    return placeManagementGetAllBranchByCityId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranchByCityId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Plain(params?: PlaceManagementGetAllBranchByCityId$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBranchDto>> {
    return this.placeManagementGetAllBranchByCityId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>): Array<OutputGetAllBranchDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranchByCityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Response(params?: PlaceManagementGetAllBranchByCityId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {
    return placeManagementGetAllBranchByCityId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranchByCityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId(params?: PlaceManagementGetAllBranchByCityId$Params, context?: HttpContext): Observable<Array<OutputGetAllBranchDto>> {
    return this.placeManagementGetAllBranchByCityId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>): Array<OutputGetAllBranchDto> => r.body)
    );
  }

  /** Path part for operation `placeManagementCreateCity()` */
  static readonly PlaceManagementCreateCityPath = '/api/admin/PlaceManagement/CreateCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Plain$Response(params?: PlaceManagementCreateCity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateCity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Plain(params?: PlaceManagementCreateCity$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateCity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Response(params?: PlaceManagementCreateCity$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateCity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity(params?: PlaceManagementCreateCity$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementDeleteCity()` */
  static readonly PlaceManagementDeleteCityPath = '/api/admin/PlaceManagement/DeleteCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Plain$Response(params?: PlaceManagementDeleteCity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteCity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Plain(params?: PlaceManagementDeleteCity$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteCity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Response(params?: PlaceManagementDeleteCity$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteCity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity(params?: PlaceManagementDeleteCity$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementUpdateCity()` */
  static readonly PlaceManagementUpdateCityPath = '/api/admin/PlaceManagement/UpdateCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Plain$Response(params?: PlaceManagementUpdateCity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateCity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Plain(params?: PlaceManagementUpdateCity$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateCity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Response(params?: PlaceManagementUpdateCity$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateCity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity(params?: PlaceManagementUpdateCity$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllCity()` */
  static readonly PlaceManagementGetAllCityPath = '/api/admin/PlaceManagement/GetAllCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCity$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Plain$Response(params?: PlaceManagementGetAllCity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {
    return placeManagementGetAllCity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCity$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Plain(params?: PlaceManagementGetAllCity$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCityDto>> {
    return this.placeManagementGetAllCity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>): Array<OutputGetAllCityDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Response(params?: PlaceManagementGetAllCity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {
    return placeManagementGetAllCity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity(params?: PlaceManagementGetAllCity$Params, context?: HttpContext): Observable<Array<OutputGetAllCityDto>> {
    return this.placeManagementGetAllCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>): Array<OutputGetAllCityDto> => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllCityByGovenorateId()` */
  static readonly PlaceManagementGetAllCityByGovenorateIdPath = '/api/admin/PlaceManagement/GetAllCityByGovenorateId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCityByGovenorateId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Plain$Response(params?: PlaceManagementGetAllCityByGovenorateId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {
    return placeManagementGetAllCityByGovenorateId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCityByGovenorateId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Plain(params?: PlaceManagementGetAllCityByGovenorateId$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCityDto>> {
    return this.placeManagementGetAllCityByGovenorateId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>): Array<OutputGetAllCityDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCityByGovenorateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Response(params?: PlaceManagementGetAllCityByGovenorateId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {
    return placeManagementGetAllCityByGovenorateId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCityByGovenorateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId(params?: PlaceManagementGetAllCityByGovenorateId$Params, context?: HttpContext): Observable<Array<OutputGetAllCityDto>> {
    return this.placeManagementGetAllCityByGovenorateId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>): Array<OutputGetAllCityDto> => r.body)
    );
  }

  /** Path part for operation `placeManagementCreateGovernorate()` */
  static readonly PlaceManagementCreateGovernoratePath = '/api/admin/PlaceManagement/CreateGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Plain$Response(params?: PlaceManagementCreateGovernorate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateGovernorate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Plain(params?: PlaceManagementCreateGovernorate$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateGovernorate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Response(params?: PlaceManagementCreateGovernorate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateGovernorate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate(params?: PlaceManagementCreateGovernorate$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateGovernorate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementDeleteGovernorate()` */
  static readonly PlaceManagementDeleteGovernoratePath = '/api/admin/PlaceManagement/DeleteGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Plain$Response(params?: PlaceManagementDeleteGovernorate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteGovernorate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Plain(params?: PlaceManagementDeleteGovernorate$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteGovernorate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Response(params?: PlaceManagementDeleteGovernorate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteGovernorate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate(params?: PlaceManagementDeleteGovernorate$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteGovernorate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementUpdateGovernorate()` */
  static readonly PlaceManagementUpdateGovernoratePath = '/api/admin/PlaceManagement/UpdateGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Plain$Response(params?: PlaceManagementUpdateGovernorate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateGovernorate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Plain(params?: PlaceManagementUpdateGovernorate$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateGovernorate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Response(params?: PlaceManagementUpdateGovernorate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateGovernorate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate(params?: PlaceManagementUpdateGovernorate$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateGovernorate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllGovernorate()` */
  static readonly PlaceManagementGetAllGovernoratePath = '/api/admin/PlaceManagement/GetAllGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllGovernorate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Plain$Response(params?: PlaceManagementGetAllGovernorate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {
    return placeManagementGetAllGovernorate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllGovernorate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Plain(params?: PlaceManagementGetAllGovernorate$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllGovernorateDto>> {
    return this.placeManagementGetAllGovernorate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>): Array<OutputGetAllGovernorateDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllGovernorate()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Response(params?: PlaceManagementGetAllGovernorate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {
    return placeManagementGetAllGovernorate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllGovernorate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate(params?: PlaceManagementGetAllGovernorate$Params, context?: HttpContext): Observable<Array<OutputGetAllGovernorateDto>> {
    return this.placeManagementGetAllGovernorate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>): Array<OutputGetAllGovernorateDto> => r.body)
    );
  }

  /** Path part for operation `placeManagementCreateCountry()` */
  static readonly PlaceManagementCreateCountryPath = '/api/admin/PlaceManagement/CreateCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Plain$Response(params?: PlaceManagementCreateCountry$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateCountry$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Plain(params?: PlaceManagementCreateCountry$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateCountry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Response(params?: PlaceManagementCreateCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementCreateCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry(params?: PlaceManagementCreateCountry$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementCreateCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementDeleteCountry()` */
  static readonly PlaceManagementDeleteCountryPath = '/api/admin/PlaceManagement/DeleteCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Plain$Response(params?: PlaceManagementDeleteCountry$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteCountry$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Plain(params?: PlaceManagementDeleteCountry$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteCountry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Response(params?: PlaceManagementDeleteCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementDeleteCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry(params?: PlaceManagementDeleteCountry$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementDeleteCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementUpdateCountry()` */
  static readonly PlaceManagementUpdateCountryPath = '/api/admin/PlaceManagement/UpdateCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Plain$Response(params?: PlaceManagementUpdateCountry$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateCountry$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Plain(params?: PlaceManagementUpdateCountry$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateCountry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Response(params?: PlaceManagementUpdateCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return placeManagementUpdateCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry(params?: PlaceManagementUpdateCountry$Params, context?: HttpContext): Observable<boolean> {
    return this.placeManagementUpdateCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `placeManagementGetAllCountry()` */
  static readonly PlaceManagementGetAllCountryPath = '/api/admin/PlaceManagement/GetAllCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCountry$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Plain$Response(params?: PlaceManagementGetAllCountry$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCountryDto>>> {
    return placeManagementGetAllCountry$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCountry$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Plain(params?: PlaceManagementGetAllCountry$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCountryDto>> {
    return this.placeManagementGetAllCountry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCountryDto>>): Array<OutputGetAllCountryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Response(params?: PlaceManagementGetAllCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCountryDto>>> {
    return placeManagementGetAllCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry(params?: PlaceManagementGetAllCountry$Params, context?: HttpContext): Observable<Array<OutputGetAllCountryDto>> {
    return this.placeManagementGetAllCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCountryDto>>): Array<OutputGetAllCountryDto> => r.body)
    );
  }

}
