import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-single-select-menu',
  templateUrl: './single-select-menu.component.html',
  styleUrls: ['./single-select-menu.component.scss'],
})
export class SingleSelectMenuComponent implements OnInit {
  @Input() id!: string;
  @Input() placeholder: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() menuList!: Array<{ name: string; value: any }>;
  @Input() separateOption: string = '';

  @Input() value!: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  selectItem(selectionValue: MatSelectChange) {
    this.valueChange.emit(selectionValue.value);
  }
}
