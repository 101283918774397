/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputGetAllFreelanceServiceDto } from '../models/output-get-all-freelance-service-dto';
import { OutputGetAllServiceCategoryDto } from '../models/output-get-all-service-category-dto';
import { serviceManagementCreateFreelanceService } from '../fn/service-management/service-management-create-freelance-service';
import { ServiceManagementCreateFreelanceService$Params } from '../fn/service-management/service-management-create-freelance-service';
import { serviceManagementCreateFreelanceService$Plain } from '../fn/service-management/service-management-create-freelance-service-plain';
import { ServiceManagementCreateFreelanceService$Plain$Params } from '../fn/service-management/service-management-create-freelance-service-plain';
import { serviceManagementCreateServiceCategory } from '../fn/service-management/service-management-create-service-category';
import { ServiceManagementCreateServiceCategory$Params } from '../fn/service-management/service-management-create-service-category';
import { serviceManagementCreateServiceCategory$Plain } from '../fn/service-management/service-management-create-service-category-plain';
import { ServiceManagementCreateServiceCategory$Plain$Params } from '../fn/service-management/service-management-create-service-category-plain';
import { serviceManagementDeleteFreelanceService } from '../fn/service-management/service-management-delete-freelance-service';
import { ServiceManagementDeleteFreelanceService$Params } from '../fn/service-management/service-management-delete-freelance-service';
import { serviceManagementDeleteFreelanceService$Plain } from '../fn/service-management/service-management-delete-freelance-service-plain';
import { ServiceManagementDeleteFreelanceService$Plain$Params } from '../fn/service-management/service-management-delete-freelance-service-plain';
import { serviceManagementDeleteServiceCategory } from '../fn/service-management/service-management-delete-service-category';
import { ServiceManagementDeleteServiceCategory$Params } from '../fn/service-management/service-management-delete-service-category';
import { serviceManagementDeleteServiceCategory$Plain } from '../fn/service-management/service-management-delete-service-category-plain';
import { ServiceManagementDeleteServiceCategory$Plain$Params } from '../fn/service-management/service-management-delete-service-category-plain';
import { serviceManagementGetAllFreelanceService } from '../fn/service-management/service-management-get-all-freelance-service';
import { ServiceManagementGetAllFreelanceService$Params } from '../fn/service-management/service-management-get-all-freelance-service';
import { serviceManagementGetAllFreelanceService$Plain } from '../fn/service-management/service-management-get-all-freelance-service-plain';
import { ServiceManagementGetAllFreelanceService$Plain$Params } from '../fn/service-management/service-management-get-all-freelance-service-plain';
import { serviceManagementGetAllServiceCategory } from '../fn/service-management/service-management-get-all-service-category';
import { ServiceManagementGetAllServiceCategory$Params } from '../fn/service-management/service-management-get-all-service-category';
import { serviceManagementGetAllServiceCategory$Plain } from '../fn/service-management/service-management-get-all-service-category-plain';
import { ServiceManagementGetAllServiceCategory$Plain$Params } from '../fn/service-management/service-management-get-all-service-category-plain';
import { serviceManagementUpdateFreelanceService } from '../fn/service-management/service-management-update-freelance-service';
import { ServiceManagementUpdateFreelanceService$Params } from '../fn/service-management/service-management-update-freelance-service';
import { serviceManagementUpdateFreelanceService$Plain } from '../fn/service-management/service-management-update-freelance-service-plain';
import { ServiceManagementUpdateFreelanceService$Plain$Params } from '../fn/service-management/service-management-update-freelance-service-plain';
import { serviceManagementUpdateServiceCategory } from '../fn/service-management/service-management-update-service-category';
import { ServiceManagementUpdateServiceCategory$Params } from '../fn/service-management/service-management-update-service-category';
import { serviceManagementUpdateServiceCategory$Plain } from '../fn/service-management/service-management-update-service-category-plain';
import { ServiceManagementUpdateServiceCategory$Plain$Params } from '../fn/service-management/service-management-update-service-category-plain';

@Injectable({ providedIn: 'root' })
export class ServiceManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `serviceManagementCreateServiceCategory()` */
  static readonly ServiceManagementCreateServiceCategoryPath = '/api/admin/ServiceManagement/CreateServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateServiceCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Plain$Response(params?: ServiceManagementCreateServiceCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementCreateServiceCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateServiceCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Plain(params?: ServiceManagementCreateServiceCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementCreateServiceCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateServiceCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Response(params?: ServiceManagementCreateServiceCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementCreateServiceCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateServiceCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory(params?: ServiceManagementCreateServiceCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementCreateServiceCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementDeleteServiceCategory()` */
  static readonly ServiceManagementDeleteServiceCategoryPath = '/api/admin/ServiceManagement/DeleteServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteServiceCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Plain$Response(params?: ServiceManagementDeleteServiceCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementDeleteServiceCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteServiceCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Plain(params?: ServiceManagementDeleteServiceCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementDeleteServiceCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteServiceCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Response(params?: ServiceManagementDeleteServiceCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementDeleteServiceCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteServiceCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory(params?: ServiceManagementDeleteServiceCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementDeleteServiceCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementUpdateServiceCategory()` */
  static readonly ServiceManagementUpdateServiceCategoryPath = '/api/admin/ServiceManagement/UpdateServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateServiceCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Plain$Response(params?: ServiceManagementUpdateServiceCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementUpdateServiceCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateServiceCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Plain(params?: ServiceManagementUpdateServiceCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementUpdateServiceCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateServiceCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Response(params?: ServiceManagementUpdateServiceCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementUpdateServiceCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateServiceCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory(params?: ServiceManagementUpdateServiceCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementUpdateServiceCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementGetAllServiceCategory()` */
  static readonly ServiceManagementGetAllServiceCategoryPath = '/api/admin/ServiceManagement/GetAllServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllServiceCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Plain$Response(params?: ServiceManagementGetAllServiceCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>> {
    return serviceManagementGetAllServiceCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllServiceCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Plain(params?: ServiceManagementGetAllServiceCategory$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllServiceCategoryDto>> {
    return this.serviceManagementGetAllServiceCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>): Array<OutputGetAllServiceCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllServiceCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Response(params?: ServiceManagementGetAllServiceCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>> {
    return serviceManagementGetAllServiceCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllServiceCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory(params?: ServiceManagementGetAllServiceCategory$Params, context?: HttpContext): Observable<Array<OutputGetAllServiceCategoryDto>> {
    return this.serviceManagementGetAllServiceCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>): Array<OutputGetAllServiceCategoryDto> => r.body)
    );
  }

  /** Path part for operation `serviceManagementCreateFreelanceService()` */
  static readonly ServiceManagementCreateFreelanceServicePath = '/api/admin/ServiceManagement/CreateFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateFreelanceService$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Plain$Response(params?: ServiceManagementCreateFreelanceService$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementCreateFreelanceService$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateFreelanceService$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Plain(params?: ServiceManagementCreateFreelanceService$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementCreateFreelanceService$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateFreelanceService()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Response(params?: ServiceManagementCreateFreelanceService$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementCreateFreelanceService(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateFreelanceService$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService(params?: ServiceManagementCreateFreelanceService$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementCreateFreelanceService$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementDeleteFreelanceService()` */
  static readonly ServiceManagementDeleteFreelanceServicePath = '/api/admin/ServiceManagement/DeleteFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteFreelanceService$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Plain$Response(params?: ServiceManagementDeleteFreelanceService$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementDeleteFreelanceService$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteFreelanceService$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Plain(params?: ServiceManagementDeleteFreelanceService$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementDeleteFreelanceService$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteFreelanceService()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Response(params?: ServiceManagementDeleteFreelanceService$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementDeleteFreelanceService(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteFreelanceService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService(params?: ServiceManagementDeleteFreelanceService$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementDeleteFreelanceService$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementUpdateFreelanceService()` */
  static readonly ServiceManagementUpdateFreelanceServicePath = '/api/admin/ServiceManagement/UpdateFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateFreelanceService$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Plain$Response(params?: ServiceManagementUpdateFreelanceService$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementUpdateFreelanceService$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateFreelanceService$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Plain(params?: ServiceManagementUpdateFreelanceService$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementUpdateFreelanceService$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateFreelanceService()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Response(params?: ServiceManagementUpdateFreelanceService$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return serviceManagementUpdateFreelanceService(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateFreelanceService$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService(params?: ServiceManagementUpdateFreelanceService$Params, context?: HttpContext): Observable<boolean> {
    return this.serviceManagementUpdateFreelanceService$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `serviceManagementGetAllFreelanceService()` */
  static readonly ServiceManagementGetAllFreelanceServicePath = '/api/admin/ServiceManagement/GetAllFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllFreelanceService$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Plain$Response(params?: ServiceManagementGetAllFreelanceService$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>> {
    return serviceManagementGetAllFreelanceService$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllFreelanceService$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Plain(params?: ServiceManagementGetAllFreelanceService$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllFreelanceServiceDto>> {
    return this.serviceManagementGetAllFreelanceService$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>): Array<OutputGetAllFreelanceServiceDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllFreelanceService()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Response(params?: ServiceManagementGetAllFreelanceService$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>> {
    return serviceManagementGetAllFreelanceService(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllFreelanceService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService(params?: ServiceManagementGetAllFreelanceService$Params, context?: HttpContext): Observable<Array<OutputGetAllFreelanceServiceDto>> {
    return this.serviceManagementGetAllFreelanceService$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>): Array<OutputGetAllFreelanceServiceDto> => r.body)
    );
  }

}
