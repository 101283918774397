import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-chip-custom',
  templateUrl: './chip-custom.component.html',
  styleUrls: ['./chip-custom.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipCustomComponent),
      multi: true,
    },
  ],
})
export class ChipCustomComponent implements ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() data: any[];
  @Input() label: string;
  @Input() placeholder: string;

  binding;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  removeTagOption(value: number): void {
    this.formControl.value.splice(value, 1);

    this.formControl.updateValueAndValidity();
  }
  tagOptionName(id) {
    if (this.data.filter((er) => er.id === +id).length > 0) {
      return this.data.filter((er) => er.id === +id)[0].name;
    } else {
      let index = this.formControl.value.indexOf(id);
      this.formControl.setValue(this.formControl.value.splice(index, 1));
      return '';
    }
  }

  filtered = [];

  filter(event) {
    this.filtered = Array.from(this.data);
    this.filtered = this.filtered.filter((em) =>
      em.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
    );
  }

  tagOptionSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.formControl.value.indexOf(+event.option.value) === -1) {
      this.formControl.value.push(+event.option.value);

      this.formControl.updateValueAndValidity();
    }
  }
  addTagOption(event: MatChipInputEvent): void {
    if (this.data.filter((er) => +er.id === +event.value).length > 0) {
      this.formControl.value.push(+event.value);

      this.formControl.updateValueAndValidity();
    }
  }
  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.binding = obj; // Assuming 'binding' is used to bind the value in your component
    }
  }
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}
