import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumber]'
})
export class PhoneNumberDirective {
  private maxLength = 12; // Maximum length of the input
  private minLength = 10; // Minimum length of the input

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    let filteredInput = inputElement.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    // Enforce maximum length
    if (filteredInput.length > this.maxLength) {
      filteredInput = filteredInput.substr(0, this.maxLength);
    }

    // Set the value of the input to the filtered input if it has changed
    if (inputElement.value !== filteredInput) {
      inputElement.value = filteredInput;
      event.stopPropagation();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    // Enforce minimum length on blur to avoid intermediate invalid state
    if (inputElement.value.length < this.minLength) {
      inputElement.value = ''; // Clear input if it doesn't meet minimum length
      inputElement.dispatchEvent(new Event('input', { bubbles: true })); // Trigger change detection
    }
  }
}
