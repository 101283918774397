/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DashboardService } from './services/dashboard.service';
import { InventoryManagementService } from './services/inventory-management.service';
import { ItemManagementService } from './services/item-management.service';
import { MerchantCompanyService } from './services/merchant-company.service';
import { MerchantManagementService } from './services/merchant-management.service';
import { OfferManagementService } from './services/offer-management.service';
import { PlaceManagementService } from './services/place-management.service';
import { ServiceManagementService } from './services/service-management.service';
import { SiteContentManagementService } from './services/site-content-management.service';
import { StylistManagementService } from './services/stylist-management.service';
import { StylistProfileManagementService } from './services/stylist-profile-management.service';
import { TrackingManagementService } from './services/tracking-management.service';
import { UserAccountService } from './services/user-account.service';
import { UserManagementService } from './services/user-management.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DashboardService,
    InventoryManagementService,
    ItemManagementService,
    MerchantCompanyService,
    MerchantManagementService,
    OfferManagementService,
    PlaceManagementService,
    ServiceManagementService,
    SiteContentManagementService,
    StylistManagementService,
    StylistProfileManagementService,
    TrackingManagementService,
    UserAccountService,
    UserManagementService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
