/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CheckMerchantHaveValidCompanyDto } from '../models/check-merchant-have-valid-company-dto';
import { merchantCompanyCheckMerchantHaveValidCompany } from '../fn/merchant-company/merchant-company-check-merchant-have-valid-company';
import { MerchantCompanyCheckMerchantHaveValidCompany$Params } from '../fn/merchant-company/merchant-company-check-merchant-have-valid-company';
import { merchantCompanyCheckMerchantHaveValidCompany$Plain } from '../fn/merchant-company/merchant-company-check-merchant-have-valid-company-plain';
import { MerchantCompanyCheckMerchantHaveValidCompany$Plain$Params } from '../fn/merchant-company/merchant-company-check-merchant-have-valid-company-plain';
import { merchantCompanyCreateCompany } from '../fn/merchant-company/merchant-company-create-company';
import { MerchantCompanyCreateCompany$Params } from '../fn/merchant-company/merchant-company-create-company';
import { merchantCompanyCreateCompany$Plain } from '../fn/merchant-company/merchant-company-create-company-plain';
import { MerchantCompanyCreateCompany$Plain$Params } from '../fn/merchant-company/merchant-company-create-company-plain';

@Injectable({ providedIn: 'root' })
export class MerchantCompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `merchantCompanyCreateCompany()` */
  static readonly MerchantCompanyCreateCompanyPath = '/api/Merchant/MerchantCompany/CreateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCreateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Plain$Response(params?: MerchantCompanyCreateCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantCompanyCreateCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCreateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Plain(params?: MerchantCompanyCreateCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantCompanyCreateCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCreateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Response(params?: MerchantCompanyCreateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantCompanyCreateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCreateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany(params?: MerchantCompanyCreateCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantCompanyCreateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantCompanyCheckMerchantHaveValidCompany()` */
  static readonly MerchantCompanyCheckMerchantHaveValidCompanyPath = '/api/Merchant/MerchantCompany/CheckMerchantHaveValidCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCheckMerchantHaveValidCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Plain$Response(params?: MerchantCompanyCheckMerchantHaveValidCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckMerchantHaveValidCompanyDto>> {
    return merchantCompanyCheckMerchantHaveValidCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCheckMerchantHaveValidCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Plain(params?: MerchantCompanyCheckMerchantHaveValidCompany$Plain$Params, context?: HttpContext): Observable<CheckMerchantHaveValidCompanyDto> {
    return this.merchantCompanyCheckMerchantHaveValidCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckMerchantHaveValidCompanyDto>): CheckMerchantHaveValidCompanyDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCheckMerchantHaveValidCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Response(params?: MerchantCompanyCheckMerchantHaveValidCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckMerchantHaveValidCompanyDto>> {
    return merchantCompanyCheckMerchantHaveValidCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCheckMerchantHaveValidCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany(params?: MerchantCompanyCheckMerchantHaveValidCompany$Params, context?: HttpContext): Observable<CheckMerchantHaveValidCompanyDto> {
    return this.merchantCompanyCheckMerchantHaveValidCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckMerchantHaveValidCompanyDto>): CheckMerchantHaveValidCompanyDto => r.body)
    );
  }

}
