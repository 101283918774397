/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetAllStylistWithdrawalForAdminDto } from '../models/get-all-stylist-withdrawal-for-admin-dto';
import { OutputAllStylistsDto } from '../models/output-all-stylists-dto';
import { OutputGetAllStylistEarningSettingDto } from '../models/output-get-all-stylist-earning-setting-dto';
import { OutputGetAllStylistFeatureDto } from '../models/output-get-all-stylist-feature-dto';
import { OutputGetAllStylistPackageDto } from '../models/output-get-all-stylist-package-dto';
import { OutputGetAllStylistPersonQuestionDto } from '../models/output-get-all-stylist-person-question-dto';
import { OutputGetAllStylistServiceQuestionDto } from '../models/output-get-all-stylist-service-question-dto';
import { StylistDashboardDto } from '../models/stylist-dashboard-dto';
import { stylistManagementCreateStylistEarningSetting } from '../fn/stylist-management/stylist-management-create-stylist-earning-setting';
import { StylistManagementCreateStylistEarningSetting$Params } from '../fn/stylist-management/stylist-management-create-stylist-earning-setting';
import { stylistManagementCreateStylistEarningSetting$Plain } from '../fn/stylist-management/stylist-management-create-stylist-earning-setting-plain';
import { StylistManagementCreateStylistEarningSetting$Plain$Params } from '../fn/stylist-management/stylist-management-create-stylist-earning-setting-plain';
import { stylistManagementCreateStylistFeature } from '../fn/stylist-management/stylist-management-create-stylist-feature';
import { StylistManagementCreateStylistFeature$Params } from '../fn/stylist-management/stylist-management-create-stylist-feature';
import { stylistManagementCreateStylistPackage } from '../fn/stylist-management/stylist-management-create-stylist-package';
import { StylistManagementCreateStylistPackage$Params } from '../fn/stylist-management/stylist-management-create-stylist-package';
import { stylistManagementCreateStylistPackage$Plain } from '../fn/stylist-management/stylist-management-create-stylist-package-plain';
import { StylistManagementCreateStylistPackage$Plain$Params } from '../fn/stylist-management/stylist-management-create-stylist-package-plain';
import { stylistManagementCreateStylistRequestPersonQuestion } from '../fn/stylist-management/stylist-management-create-stylist-request-person-question';
import { StylistManagementCreateStylistRequestPersonQuestion$Params } from '../fn/stylist-management/stylist-management-create-stylist-request-person-question';
import { stylistManagementCreateStylistRequestQuestion } from '../fn/stylist-management/stylist-management-create-stylist-request-question';
import { StylistManagementCreateStylistRequestQuestion$Params } from '../fn/stylist-management/stylist-management-create-stylist-request-question';
import { stylistManagementDeleteStylistFeature } from '../fn/stylist-management/stylist-management-delete-stylist-feature';
import { StylistManagementDeleteStylistFeature$Params } from '../fn/stylist-management/stylist-management-delete-stylist-feature';
import { stylistManagementDeleteStylistPackage } from '../fn/stylist-management/stylist-management-delete-stylist-package';
import { StylistManagementDeleteStylistPackage$Params } from '../fn/stylist-management/stylist-management-delete-stylist-package';
import { stylistManagementDeleteStylistPackage$Plain } from '../fn/stylist-management/stylist-management-delete-stylist-package-plain';
import { StylistManagementDeleteStylistPackage$Plain$Params } from '../fn/stylist-management/stylist-management-delete-stylist-package-plain';
import { stylistManagementDeleteStylistRequestPersonQuestion } from '../fn/stylist-management/stylist-management-delete-stylist-request-person-question';
import { StylistManagementDeleteStylistRequestPersonQuestion$Params } from '../fn/stylist-management/stylist-management-delete-stylist-request-person-question';
import { stylistManagementDeleteStylistRequestQuestion } from '../fn/stylist-management/stylist-management-delete-stylist-request-question';
import { StylistManagementDeleteStylistRequestQuestion$Params } from '../fn/stylist-management/stylist-management-delete-stylist-request-question';
import { stylistManagementGetAllPendingStylists } from '../fn/stylist-management/stylist-management-get-all-pending-stylists';
import { StylistManagementGetAllPendingStylists$Params } from '../fn/stylist-management/stylist-management-get-all-pending-stylists';
import { stylistManagementGetAllPendingStylists$Plain } from '../fn/stylist-management/stylist-management-get-all-pending-stylists-plain';
import { StylistManagementGetAllPendingStylists$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-pending-stylists-plain';
import { stylistManagementGetAllRejectedStylists } from '../fn/stylist-management/stylist-management-get-all-rejected-stylists';
import { StylistManagementGetAllRejectedStylists$Params } from '../fn/stylist-management/stylist-management-get-all-rejected-stylists';
import { stylistManagementGetAllRejectedStylists$Plain } from '../fn/stylist-management/stylist-management-get-all-rejected-stylists-plain';
import { StylistManagementGetAllRejectedStylists$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-rejected-stylists-plain';
import { stylistManagementGetAllStylistEarningSetting } from '../fn/stylist-management/stylist-management-get-all-stylist-earning-setting';
import { StylistManagementGetAllStylistEarningSetting$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-earning-setting';
import { stylistManagementGetAllStylistEarningSetting$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-earning-setting-plain';
import { StylistManagementGetAllStylistEarningSetting$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-earning-setting-plain';
import { stylistManagementGetAllStylistFeature } from '../fn/stylist-management/stylist-management-get-all-stylist-feature';
import { StylistManagementGetAllStylistFeature$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-feature';
import { stylistManagementGetAllStylistFeature$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-feature-plain';
import { StylistManagementGetAllStylistFeature$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-feature-plain';
import { stylistManagementGetAllStylistPackage } from '../fn/stylist-management/stylist-management-get-all-stylist-package';
import { StylistManagementGetAllStylistPackage$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-package';
import { stylistManagementGetAllStylistPackage$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-package-plain';
import { StylistManagementGetAllStylistPackage$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-package-plain';
import { stylistManagementGetAllStylistRequestPersonQuestion } from '../fn/stylist-management/stylist-management-get-all-stylist-request-person-question';
import { StylistManagementGetAllStylistRequestPersonQuestion$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-request-person-question';
import { stylistManagementGetAllStylistRequestPersonQuestion$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-request-person-question-plain';
import { StylistManagementGetAllStylistRequestPersonQuestion$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-request-person-question-plain';
import { stylistManagementGetAllStylistRequestQuestion } from '../fn/stylist-management/stylist-management-get-all-stylist-request-question';
import { StylistManagementGetAllStylistRequestQuestion$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-request-question';
import { stylistManagementGetAllStylistRequestQuestion$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-request-question-plain';
import { StylistManagementGetAllStylistRequestQuestion$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-request-question-plain';
import { stylistManagementGetAllStylists } from '../fn/stylist-management/stylist-management-get-all-stylists';
import { StylistManagementGetAllStylists$Params } from '../fn/stylist-management/stylist-management-get-all-stylists';
import { stylistManagementGetAllStylists$Plain } from '../fn/stylist-management/stylist-management-get-all-stylists-plain';
import { StylistManagementGetAllStylists$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylists-plain';
import { stylistManagementGetAllStylistWithdrawalForAdmin } from '../fn/stylist-management/stylist-management-get-all-stylist-withdrawal-for-admin';
import { StylistManagementGetAllStylistWithdrawalForAdmin$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-withdrawal-for-admin';
import { stylistManagementGetAllStylistWithdrawalForAdmin$Plain } from '../fn/stylist-management/stylist-management-get-all-stylist-withdrawal-for-admin-plain';
import { StylistManagementGetAllStylistWithdrawalForAdmin$Plain$Params } from '../fn/stylist-management/stylist-management-get-all-stylist-withdrawal-for-admin-plain';
import { stylistManagementGetStylistDashboard } from '../fn/stylist-management/stylist-management-get-stylist-dashboard';
import { StylistManagementGetStylistDashboard$Params } from '../fn/stylist-management/stylist-management-get-stylist-dashboard';
import { stylistManagementGetStylistDashboard$Plain } from '../fn/stylist-management/stylist-management-get-stylist-dashboard-plain';
import { StylistManagementGetStylistDashboard$Plain$Params } from '../fn/stylist-management/stylist-management-get-stylist-dashboard-plain';
import { stylistManagementStylistChangeStatues } from '../fn/stylist-management/stylist-management-stylist-change-statues';
import { StylistManagementStylistChangeStatues$Params } from '../fn/stylist-management/stylist-management-stylist-change-statues';
import { stylistManagementStylistChangeStatues$Plain } from '../fn/stylist-management/stylist-management-stylist-change-statues-plain';
import { StylistManagementStylistChangeStatues$Plain$Params } from '../fn/stylist-management/stylist-management-stylist-change-statues-plain';
import { stylistManagementUpdateStylistDashboard } from '../fn/stylist-management/stylist-management-update-stylist-dashboard';
import { StylistManagementUpdateStylistDashboard$Params } from '../fn/stylist-management/stylist-management-update-stylist-dashboard';
import { stylistManagementUpdateStylistDashboard$Plain } from '../fn/stylist-management/stylist-management-update-stylist-dashboard-plain';
import { StylistManagementUpdateStylistDashboard$Plain$Params } from '../fn/stylist-management/stylist-management-update-stylist-dashboard-plain';
import { stylistManagementUpdateStylistFeature } from '../fn/stylist-management/stylist-management-update-stylist-feature';
import { StylistManagementUpdateStylistFeature$Params } from '../fn/stylist-management/stylist-management-update-stylist-feature';
import { stylistManagementUpdateStylistPackage } from '../fn/stylist-management/stylist-management-update-stylist-package';
import { StylistManagementUpdateStylistPackage$Params } from '../fn/stylist-management/stylist-management-update-stylist-package';
import { stylistManagementUpdateStylistPackage$Plain } from '../fn/stylist-management/stylist-management-update-stylist-package-plain';
import { StylistManagementUpdateStylistPackage$Plain$Params } from '../fn/stylist-management/stylist-management-update-stylist-package-plain';
import { stylistManagementUpdateStylistRequestPersonQuestion } from '../fn/stylist-management/stylist-management-update-stylist-request-person-question';
import { StylistManagementUpdateStylistRequestPersonQuestion$Params } from '../fn/stylist-management/stylist-management-update-stylist-request-person-question';
import { stylistManagementUpdateStylistServiceQuestion } from '../fn/stylist-management/stylist-management-update-stylist-service-question';
import { StylistManagementUpdateStylistServiceQuestion$Params } from '../fn/stylist-management/stylist-management-update-stylist-service-question';
import { stylistManagementUpdateStylistWithdrawalForAdmin } from '../fn/stylist-management/stylist-management-update-stylist-withdrawal-for-admin';
import { StylistManagementUpdateStylistWithdrawalForAdmin$Params } from '../fn/stylist-management/stylist-management-update-stylist-withdrawal-for-admin';
import { stylistManagementUpdateStylistWithdrawalForAdmin$Plain } from '../fn/stylist-management/stylist-management-update-stylist-withdrawal-for-admin-plain';
import { StylistManagementUpdateStylistWithdrawalForAdmin$Plain$Params } from '../fn/stylist-management/stylist-management-update-stylist-withdrawal-for-admin-plain';

@Injectable({ providedIn: 'root' })
export class StylistManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `stylistManagementCreateStylistRequestQuestion()` */
  static readonly StylistManagementCreateStylistRequestQuestionPath = '/api/admin/StylistManagement/CreateStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistRequestQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestQuestion$Response(params?: StylistManagementCreateStylistRequestQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementCreateStylistRequestQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistRequestQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestQuestion(params?: StylistManagementCreateStylistRequestQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementCreateStylistRequestQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementDeleteStylistRequestQuestion()` */
  static readonly StylistManagementDeleteStylistRequestQuestionPath = '/api/admin/StylistManagement/DeleteStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistRequestQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestQuestion$Response(params?: StylistManagementDeleteStylistRequestQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementDeleteStylistRequestQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistRequestQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestQuestion(params?: StylistManagementDeleteStylistRequestQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementDeleteStylistRequestQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistServiceQuestion()` */
  static readonly StylistManagementUpdateStylistServiceQuestionPath = '/api/admin/StylistManagement/UpdateStylistServiceQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistServiceQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistServiceQuestion$Response(params?: StylistManagementUpdateStylistServiceQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementUpdateStylistServiceQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistServiceQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistServiceQuestion(params?: StylistManagementUpdateStylistServiceQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementUpdateStylistServiceQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistRequestQuestion()` */
  static readonly StylistManagementGetAllStylistRequestQuestionPath = '/api/admin/StylistManagement/GetAllStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Plain$Response(params?: StylistManagementGetAllStylistRequestQuestion$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {
    return stylistManagementGetAllStylistRequestQuestion$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Plain(params?: StylistManagementGetAllStylistRequestQuestion$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {
    return this.stylistManagementGetAllStylistRequestQuestion$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>): Array<OutputGetAllStylistServiceQuestionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Response(params?: StylistManagementGetAllStylistRequestQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {
    return stylistManagementGetAllStylistRequestQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion(params?: StylistManagementGetAllStylistRequestQuestion$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {
    return this.stylistManagementGetAllStylistRequestQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>): Array<OutputGetAllStylistServiceQuestionDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementCreateStylistRequestPersonQuestion()` */
  static readonly StylistManagementCreateStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/CreateStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistRequestPersonQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestPersonQuestion$Response(params?: StylistManagementCreateStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementCreateStylistRequestPersonQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistRequestPersonQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestPersonQuestion(params?: StylistManagementCreateStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementCreateStylistRequestPersonQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementDeleteStylistRequestPersonQuestion()` */
  static readonly StylistManagementDeleteStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/DeleteStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistRequestPersonQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestPersonQuestion$Response(params?: StylistManagementDeleteStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementDeleteStylistRequestPersonQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistRequestPersonQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestPersonQuestion(params?: StylistManagementDeleteStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementDeleteStylistRequestPersonQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistRequestPersonQuestion()` */
  static readonly StylistManagementUpdateStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/UpdateStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistRequestPersonQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistRequestPersonQuestion$Response(params?: StylistManagementUpdateStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementUpdateStylistRequestPersonQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistRequestPersonQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistRequestPersonQuestion(params?: StylistManagementUpdateStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementUpdateStylistRequestPersonQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistRequestPersonQuestion()` */
  static readonly StylistManagementGetAllStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/GetAllStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestPersonQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response(params?: StylistManagementGetAllStylistRequestPersonQuestion$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {
    return stylistManagementGetAllStylistRequestPersonQuestion$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Plain(params?: StylistManagementGetAllStylistRequestPersonQuestion$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {
    return this.stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>): Array<OutputGetAllStylistPersonQuestionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestPersonQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Response(params?: StylistManagementGetAllStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {
    return stylistManagementGetAllStylistRequestPersonQuestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestPersonQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion(params?: StylistManagementGetAllStylistRequestPersonQuestion$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {
    return this.stylistManagementGetAllStylistRequestPersonQuestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>): Array<OutputGetAllStylistPersonQuestionDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementCreateStylistPackage()` */
  static readonly StylistManagementCreateStylistPackagePath = '/api/admin/StylistManagement/CreateStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Plain$Response(params?: StylistManagementCreateStylistPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementCreateStylistPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Plain(params?: StylistManagementCreateStylistPackage$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementCreateStylistPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Response(params?: StylistManagementCreateStylistPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementCreateStylistPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage(params?: StylistManagementCreateStylistPackage$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementCreateStylistPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementDeleteStylistPackage()` */
  static readonly StylistManagementDeleteStylistPackagePath = '/api/admin/StylistManagement/DeleteStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Plain$Response(params?: StylistManagementDeleteStylistPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementDeleteStylistPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Plain(params?: StylistManagementDeleteStylistPackage$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementDeleteStylistPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Response(params?: StylistManagementDeleteStylistPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementDeleteStylistPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage(params?: StylistManagementDeleteStylistPackage$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementDeleteStylistPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistPackage()` */
  static readonly StylistManagementUpdateStylistPackagePath = '/api/admin/StylistManagement/UpdateStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Plain$Response(params?: StylistManagementUpdateStylistPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Plain(params?: StylistManagementUpdateStylistPackage$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Response(params?: StylistManagementUpdateStylistPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage(params?: StylistManagementUpdateStylistPackage$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistPackage()` */
  static readonly StylistManagementGetAllStylistPackagePath = '/api/admin/StylistManagement/GetAllStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistPackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Plain$Response(params?: StylistManagementGetAllStylistPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {
    return stylistManagementGetAllStylistPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistPackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Plain(params?: StylistManagementGetAllStylistPackage$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistPackageDto>> {
    return this.stylistManagementGetAllStylistPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>): Array<OutputGetAllStylistPackageDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Response(params?: StylistManagementGetAllStylistPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {
    return stylistManagementGetAllStylistPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage(params?: StylistManagementGetAllStylistPackage$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistPackageDto>> {
    return this.stylistManagementGetAllStylistPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>): Array<OutputGetAllStylistPackageDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementCreateStylistFeature()` */
  static readonly StylistManagementCreateStylistFeaturePath = '/api/admin/StylistManagement/CreateStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistFeature$Response(params?: StylistManagementCreateStylistFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementCreateStylistFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistFeature(params?: StylistManagementCreateStylistFeature$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementCreateStylistFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistFeature()` */
  static readonly StylistManagementGetAllStylistFeaturePath = '/api/admin/StylistManagement/GetAllStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Plain$Response(params?: StylistManagementGetAllStylistFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>> {
    return stylistManagementGetAllStylistFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Plain(params?: StylistManagementGetAllStylistFeature$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistFeatureDto>> {
    return this.stylistManagementGetAllStylistFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>): Array<OutputGetAllStylistFeatureDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Response(params?: StylistManagementGetAllStylistFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>> {
    return stylistManagementGetAllStylistFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature(params?: StylistManagementGetAllStylistFeature$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistFeatureDto>> {
    return this.stylistManagementGetAllStylistFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>): Array<OutputGetAllStylistFeatureDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementDeleteStylistFeature()` */
  static readonly StylistManagementDeleteStylistFeaturePath = '/api/admin/StylistManagement/DeleteStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistFeature$Response(params?: StylistManagementDeleteStylistFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementDeleteStylistFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistFeature(params?: StylistManagementDeleteStylistFeature$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementDeleteStylistFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistFeature()` */
  static readonly StylistManagementUpdateStylistFeaturePath = '/api/admin/StylistManagement/UpdateStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistFeature$Response(params?: StylistManagementUpdateStylistFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return stylistManagementUpdateStylistFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistFeature(params?: StylistManagementUpdateStylistFeature$Params, context?: HttpContext): Observable<void> {
    return this.stylistManagementUpdateStylistFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylists()` */
  static readonly StylistManagementGetAllStylistsPath = '/api/admin/StylistManagement/GetAllStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Plain$Response(params?: StylistManagementGetAllStylists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllStylists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Plain(params?: StylistManagementGetAllStylists$Plain$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllStylists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Response(params?: StylistManagementGetAllStylists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllStylists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists(params?: StylistManagementGetAllStylists$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllStylists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllPendingStylists()` */
  static readonly StylistManagementGetAllPendingStylistsPath = '/api/admin/StylistManagement/GetAllPendingStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllPendingStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Plain$Response(params?: StylistManagementGetAllPendingStylists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllPendingStylists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllPendingStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Plain(params?: StylistManagementGetAllPendingStylists$Plain$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllPendingStylists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllPendingStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Response(params?: StylistManagementGetAllPendingStylists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllPendingStylists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllPendingStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists(params?: StylistManagementGetAllPendingStylists$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllPendingStylists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllRejectedStylists()` */
  static readonly StylistManagementGetAllRejectedStylistsPath = '/api/admin/StylistManagement/GetAllRejectedStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllRejectedStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Plain$Response(params?: StylistManagementGetAllRejectedStylists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllRejectedStylists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllRejectedStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Plain(params?: StylistManagementGetAllRejectedStylists$Plain$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllRejectedStylists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllRejectedStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Response(params?: StylistManagementGetAllRejectedStylists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {
    return stylistManagementGetAllRejectedStylists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllRejectedStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists(params?: StylistManagementGetAllRejectedStylists$Params, context?: HttpContext): Observable<Array<OutputAllStylistsDto>> {
    return this.stylistManagementGetAllRejectedStylists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>): Array<OutputAllStylistsDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementStylistChangeStatues()` */
  static readonly StylistManagementStylistChangeStatuesPath = '/api/admin/StylistManagement/StylistChangeStatues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementStylistChangeStatues$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Plain$Response(params?: StylistManagementStylistChangeStatues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementStylistChangeStatues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementStylistChangeStatues$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Plain(params?: StylistManagementStylistChangeStatues$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementStylistChangeStatues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementStylistChangeStatues()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Response(params?: StylistManagementStylistChangeStatues$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementStylistChangeStatues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementStylistChangeStatues$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues(params?: StylistManagementStylistChangeStatues$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementStylistChangeStatues$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementCreateStylistEarningSetting()` */
  static readonly StylistManagementCreateStylistEarningSettingPath = '/api/admin/StylistManagement/CreateStylistEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Plain$Response(params?: StylistManagementCreateStylistEarningSetting$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementCreateStylistEarningSetting$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Plain(params?: StylistManagementCreateStylistEarningSetting$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementCreateStylistEarningSetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Response(params?: StylistManagementCreateStylistEarningSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementCreateStylistEarningSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting(params?: StylistManagementCreateStylistEarningSetting$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementCreateStylistEarningSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistEarningSetting()` */
  static readonly StylistManagementGetAllStylistEarningSettingPath = '/api/admin/StylistManagement/GetAllStylistEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistEarningSetting$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Plain$Response(params?: StylistManagementGetAllStylistEarningSetting$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>> {
    return stylistManagementGetAllStylistEarningSetting$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistEarningSetting$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Plain(params?: StylistManagementGetAllStylistEarningSetting$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistEarningSettingDto>> {
    return this.stylistManagementGetAllStylistEarningSetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>): Array<OutputGetAllStylistEarningSettingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistEarningSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Response(params?: StylistManagementGetAllStylistEarningSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>> {
    return stylistManagementGetAllStylistEarningSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistEarningSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting(params?: StylistManagementGetAllStylistEarningSetting$Params, context?: HttpContext): Observable<Array<OutputGetAllStylistEarningSettingDto>> {
    return this.stylistManagementGetAllStylistEarningSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>): Array<OutputGetAllStylistEarningSettingDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistDashboard()` */
  static readonly StylistManagementUpdateStylistDashboardPath = '/api/admin/StylistManagement/UpdateStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistDashboard$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Plain$Response(params?: StylistManagementUpdateStylistDashboard$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistDashboard$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistDashboard$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Plain(params?: StylistManagementUpdateStylistDashboard$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistDashboard$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistDashboard()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Response(params?: StylistManagementUpdateStylistDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistDashboard$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard(params?: StylistManagementUpdateStylistDashboard$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetStylistDashboard()` */
  static readonly StylistManagementGetStylistDashboardPath = '/api/admin/StylistManagement/GetStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetStylistDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Plain$Response(params?: StylistManagementGetStylistDashboard$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StylistDashboardDto>> {
    return stylistManagementGetStylistDashboard$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetStylistDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Plain(params?: StylistManagementGetStylistDashboard$Plain$Params, context?: HttpContext): Observable<StylistDashboardDto> {
    return this.stylistManagementGetStylistDashboard$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>): StylistDashboardDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetStylistDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Response(params?: StylistManagementGetStylistDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<StylistDashboardDto>> {
    return stylistManagementGetStylistDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetStylistDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard(params?: StylistManagementGetStylistDashboard$Params, context?: HttpContext): Observable<StylistDashboardDto> {
    return this.stylistManagementGetStylistDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>): StylistDashboardDto => r.body)
    );
  }

  /** Path part for operation `stylistManagementGetAllStylistWithdrawalForAdmin()` */
  static readonly StylistManagementGetAllStylistWithdrawalForAdminPath = '/api/admin/StylistManagement/GetAllStylistWithdrawalForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistWithdrawalForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response(params?: StylistManagementGetAllStylistWithdrawalForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>> {
    return stylistManagementGetAllStylistWithdrawalForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Plain(params?: StylistManagementGetAllStylistWithdrawalForAdmin$Plain$Params, context?: HttpContext): Observable<Array<GetAllStylistWithdrawalForAdminDto>> {
    return this.stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>): Array<GetAllStylistWithdrawalForAdminDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistWithdrawalForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Response(params?: StylistManagementGetAllStylistWithdrawalForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>> {
    return stylistManagementGetAllStylistWithdrawalForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistWithdrawalForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin(params?: StylistManagementGetAllStylistWithdrawalForAdmin$Params, context?: HttpContext): Observable<Array<GetAllStylistWithdrawalForAdminDto>> {
    return this.stylistManagementGetAllStylistWithdrawalForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>): Array<GetAllStylistWithdrawalForAdminDto> => r.body)
    );
  }

  /** Path part for operation `stylistManagementUpdateStylistWithdrawalForAdmin()` */
  static readonly StylistManagementUpdateStylistWithdrawalForAdminPath = '/api/admin/StylistManagement/UpdateStylistWithdrawalForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistWithdrawalForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response(params?: StylistManagementUpdateStylistWithdrawalForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistWithdrawalForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Plain(params?: StylistManagementUpdateStylistWithdrawalForAdmin$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistWithdrawalForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Response(params?: StylistManagementUpdateStylistWithdrawalForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return stylistManagementUpdateStylistWithdrawalForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistWithdrawalForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin(params?: StylistManagementUpdateStylistWithdrawalForAdmin$Params, context?: HttpContext): Observable<boolean> {
    return this.stylistManagementUpdateStylistWithdrawalForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
