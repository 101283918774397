/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InputDeleteSizeNameDto } from '../../models/input-delete-size-name-dto';

export interface ItemManagementDeleteSizeName$Params {
      body?: InputDeleteSizeNameDto
}

export function itemManagementDeleteSizeName(http: HttpClient, rootUrl: string, params?: ItemManagementDeleteSizeName$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, itemManagementDeleteSizeName.PATH, 'delete');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

itemManagementDeleteSizeName.PATH = '/api/admin/ItemManagement/DeleteSizeName';
