/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OutputGetAllBundleDto } from '../../models/output-get-all-bundle-dto';

export interface OfferManagementGetAllBundles$Params {
}

export function offerManagementGetAllBundles(http: HttpClient, rootUrl: string, params?: OfferManagementGetAllBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {
  const rb = new RequestBuilder(rootUrl, offerManagementGetAllBundles.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<OutputGetAllBundleDto>>;
    })
  );
}

offerManagementGetAllBundles.PATH = '/api/admin/OfferManagement/GetAllBundles';
