import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './../shared/loader/LoaderService';

@Injectable({ providedIn: 'root' })
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token')),
    });

    console.log();
    let timeExeded = false;
    const time = setTimeout(() => {
      this.loaderService.show();
      timeExeded = true;
    }, 1000);
    return next.handle(authReq).pipe(
      finalize(() => {
        if (!timeExeded) {
          clearTimeout(time);
        }
        this.loaderService.hide();
      })
    );
  }
}
