/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputForgetPasswordDto } from '../models/output-forget-password-dto';
import { OutputPagePermissionDto } from '../models/output-page-permission-dto';
import { OutputRefreshTokenDto } from '../models/output-refresh-token-dto';
import { OutputSignInUserDto } from '../models/output-sign-in-user-dto';
import { OutputVerificationPasswordDto } from '../models/output-verification-password-dto';
import { userAccountForgetPassword } from '../fn/user-account/user-account-forget-password';
import { UserAccountForgetPassword$Params } from '../fn/user-account/user-account-forget-password';
import { userAccountForgetPassword$Plain } from '../fn/user-account/user-account-forget-password-plain';
import { UserAccountForgetPassword$Plain$Params } from '../fn/user-account/user-account-forget-password-plain';
import { userAccountGetPagePermission } from '../fn/user-account/user-account-get-page-permission';
import { UserAccountGetPagePermission$Params } from '../fn/user-account/user-account-get-page-permission';
import { userAccountGetPagePermission$Plain } from '../fn/user-account/user-account-get-page-permission-plain';
import { UserAccountGetPagePermission$Plain$Params } from '../fn/user-account/user-account-get-page-permission-plain';
import { userAccountResetPassword } from '../fn/user-account/user-account-reset-password';
import { UserAccountResetPassword$Params } from '../fn/user-account/user-account-reset-password';
import { userAccountResetPassword$Plain } from '../fn/user-account/user-account-reset-password-plain';
import { UserAccountResetPassword$Plain$Params } from '../fn/user-account/user-account-reset-password-plain';
import { userAccountSignIn } from '../fn/user-account/user-account-sign-in';
import { UserAccountSignIn$Params } from '../fn/user-account/user-account-sign-in';
import { userAccountSignIn$Plain } from '../fn/user-account/user-account-sign-in-plain';
import { UserAccountSignIn$Plain$Params } from '../fn/user-account/user-account-sign-in-plain';
import { userAccountToken } from '../fn/user-account/user-account-token';
import { UserAccountToken$Params } from '../fn/user-account/user-account-token';
import { userAccountToken$Plain } from '../fn/user-account/user-account-token-plain';
import { UserAccountToken$Plain$Params } from '../fn/user-account/user-account-token-plain';
import { userAccountVerificationPinForgetPassword } from '../fn/user-account/user-account-verification-pin-forget-password';
import { UserAccountVerificationPinForgetPassword$Params } from '../fn/user-account/user-account-verification-pin-forget-password';
import { userAccountVerificationPinForgetPassword$Plain } from '../fn/user-account/user-account-verification-pin-forget-password-plain';
import { UserAccountVerificationPinForgetPassword$Plain$Params } from '../fn/user-account/user-account-verification-pin-forget-password-plain';

@Injectable({ providedIn: 'root' })
export class UserAccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userAccountSignIn()` */
  static readonly UserAccountSignInPath = '/api/admin/UserAccount/SignIn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountSignIn$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Plain$Response(params?: UserAccountSignIn$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputSignInUserDto>> {
    return userAccountSignIn$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountSignIn$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Plain(params?: UserAccountSignIn$Plain$Params, context?: HttpContext): Observable<OutputSignInUserDto> {
    return this.userAccountSignIn$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputSignInUserDto>): OutputSignInUserDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Response(params?: UserAccountSignIn$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputSignInUserDto>> {
    return userAccountSignIn(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn(params?: UserAccountSignIn$Params, context?: HttpContext): Observable<OutputSignInUserDto> {
    return this.userAccountSignIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputSignInUserDto>): OutputSignInUserDto => r.body)
    );
  }

  /** Path part for operation `userAccountToken()` */
  static readonly UserAccountTokenPath = '/api/admin/UserAccount/Token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountToken$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Plain$Response(params?: UserAccountToken$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputRefreshTokenDto>> {
    return userAccountToken$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountToken$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Plain(params?: UserAccountToken$Plain$Params, context?: HttpContext): Observable<OutputRefreshTokenDto> {
    return this.userAccountToken$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputRefreshTokenDto>): OutputRefreshTokenDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Response(params?: UserAccountToken$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputRefreshTokenDto>> {
    return userAccountToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken(params?: UserAccountToken$Params, context?: HttpContext): Observable<OutputRefreshTokenDto> {
    return this.userAccountToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputRefreshTokenDto>): OutputRefreshTokenDto => r.body)
    );
  }

  /** Path part for operation `userAccountForgetPassword()` */
  static readonly UserAccountForgetPasswordPath = '/api/admin/UserAccount/ForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Plain$Response(params?: UserAccountForgetPassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {
    return userAccountForgetPassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Plain(params?: UserAccountForgetPassword$Plain$Params, context?: HttpContext): Observable<OutputForgetPasswordDto> {
    return this.userAccountForgetPassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>): OutputForgetPasswordDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Response(params?: UserAccountForgetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {
    return userAccountForgetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword(params?: UserAccountForgetPassword$Params, context?: HttpContext): Observable<OutputForgetPasswordDto> {
    return this.userAccountForgetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>): OutputForgetPasswordDto => r.body)
    );
  }

  /** Path part for operation `userAccountVerificationPinForgetPassword()` */
  static readonly UserAccountVerificationPinForgetPasswordPath = '/api/admin/UserAccount/VerificationPinForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountVerificationPinForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Plain$Response(params?: UserAccountVerificationPinForgetPassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {
    return userAccountVerificationPinForgetPassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountVerificationPinForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Plain(params?: UserAccountVerificationPinForgetPassword$Plain$Params, context?: HttpContext): Observable<OutputVerificationPasswordDto> {
    return this.userAccountVerificationPinForgetPassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>): OutputVerificationPasswordDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountVerificationPinForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Response(params?: UserAccountVerificationPinForgetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {
    return userAccountVerificationPinForgetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountVerificationPinForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword(params?: UserAccountVerificationPinForgetPassword$Params, context?: HttpContext): Observable<OutputVerificationPasswordDto> {
    return this.userAccountVerificationPinForgetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>): OutputVerificationPasswordDto => r.body)
    );
  }

  /** Path part for operation `userAccountResetPassword()` */
  static readonly UserAccountResetPasswordPath = '/api/admin/UserAccount/ResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Plain$Response(params?: UserAccountResetPassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userAccountResetPassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Plain(params?: UserAccountResetPassword$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userAccountResetPassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountResetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Response(params?: UserAccountResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userAccountResetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountResetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword(params?: UserAccountResetPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.userAccountResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userAccountGetPagePermission()` */
  static readonly UserAccountGetPagePermissionPath = '/api/admin/UserAccount/GetPagePermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountGetPagePermission$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Plain$Response(params?: UserAccountGetPagePermission$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputPagePermissionDto>>> {
    return userAccountGetPagePermission$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountGetPagePermission$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Plain(params?: UserAccountGetPagePermission$Plain$Params, context?: HttpContext): Observable<Array<OutputPagePermissionDto>> {
    return this.userAccountGetPagePermission$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputPagePermissionDto>>): Array<OutputPagePermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountGetPagePermission()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Response(params?: UserAccountGetPagePermission$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputPagePermissionDto>>> {
    return userAccountGetPagePermission(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAccountGetPagePermission$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission(params?: UserAccountGetPagePermission$Params, context?: HttpContext): Observable<Array<OutputPagePermissionDto>> {
    return this.userAccountGetPagePermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputPagePermissionDto>>): Array<OutputPagePermissionDto> => r.body)
    );
  }

}
