<div class=" p-relative">
  <label class="text-lable">
    {{ label | translate }}
  </label>

  <mat-chip-grid [(ngModel)]="binding" #chipList23 aria-label="Fruit selection">
    <mat-chip-row *ngFor="let tagOption of formControl.value; let i = index" (removed)="removeTagOption(i)">
      {{ tagOptionName(tagOption) }}
      <button matChipRemove [attr.aria-label]="tagOptionName(tagOption)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      style="border: unset"
      #styleInput1
      (keydown)="filter($event)"
      (keypress)="filter($event)"
      (keyup)="filter($event)"
      (mouseenter)="filter($event)"
      placeholder="{{ placeholder | translate }}"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList23"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTagOption($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagOptionSelected($event); styleInput1.value = null">
    <mat-option *ngFor="let tag of filtered" value="{{ tag.id }}">
      {{ tag.name }}
    </mat-option>
  </mat-autocomplete>
</div>
