/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { inventoryManagementCreateInventoryHistorics } from '../fn/inventory-management/inventory-management-create-inventory-historics';
import { InventoryManagementCreateInventoryHistorics$Params } from '../fn/inventory-management/inventory-management-create-inventory-historics';
import { inventoryManagementCreateInventoryHistorics$Plain } from '../fn/inventory-management/inventory-management-create-inventory-historics-plain';
import { InventoryManagementCreateInventoryHistorics$Plain$Params } from '../fn/inventory-management/inventory-management-create-inventory-historics-plain';
import { inventoryManagementGetAllInventoryHistorics } from '../fn/inventory-management/inventory-management-get-all-inventory-historics';
import { InventoryManagementGetAllInventoryHistorics$Params } from '../fn/inventory-management/inventory-management-get-all-inventory-historics';
import { inventoryManagementGetAllInventoryHistorics$Plain } from '../fn/inventory-management/inventory-management-get-all-inventory-historics-plain';
import { InventoryManagementGetAllInventoryHistorics$Plain$Params } from '../fn/inventory-management/inventory-management-get-all-inventory-historics-plain';
import { inventoryManagementUpdateInventoryHistorics } from '../fn/inventory-management/inventory-management-update-inventory-historics';
import { InventoryManagementUpdateInventoryHistorics$Params } from '../fn/inventory-management/inventory-management-update-inventory-historics';
import { inventoryManagementUpdateInventoryHistorics$Plain } from '../fn/inventory-management/inventory-management-update-inventory-historics-plain';
import { InventoryManagementUpdateInventoryHistorics$Plain$Params } from '../fn/inventory-management/inventory-management-update-inventory-historics-plain';
import { OutputGetInventoryHistoricDto } from '../models/output-get-inventory-historic-dto';

@Injectable({ providedIn: 'root' })
export class InventoryManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inventoryManagementCreateInventoryHistorics()` */
  static readonly InventoryManagementCreateInventoryHistoricsPath = '/api/admin/InventoryManagement/CreateInventoryHistorics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementCreateInventoryHistorics$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementCreateInventoryHistorics$Plain$Response(params?: InventoryManagementCreateInventoryHistorics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inventoryManagementCreateInventoryHistorics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementCreateInventoryHistorics$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementCreateInventoryHistorics$Plain(params?: InventoryManagementCreateInventoryHistorics$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.inventoryManagementCreateInventoryHistorics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementCreateInventoryHistorics()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementCreateInventoryHistorics$Response(params?: InventoryManagementCreateInventoryHistorics$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inventoryManagementCreateInventoryHistorics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementCreateInventoryHistorics$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementCreateInventoryHistorics(params?: InventoryManagementCreateInventoryHistorics$Params, context?: HttpContext): Observable<boolean> {
    return this.inventoryManagementCreateInventoryHistorics$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `inventoryManagementUpdateInventoryHistorics()` */
  static readonly InventoryManagementUpdateInventoryHistoricsPath = '/api/admin/InventoryManagement/UpdateInventoryHistorics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementUpdateInventoryHistorics$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementUpdateInventoryHistorics$Plain$Response(params?: InventoryManagementUpdateInventoryHistorics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inventoryManagementUpdateInventoryHistorics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementUpdateInventoryHistorics$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementUpdateInventoryHistorics$Plain(params?: InventoryManagementUpdateInventoryHistorics$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.inventoryManagementUpdateInventoryHistorics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementUpdateInventoryHistorics()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementUpdateInventoryHistorics$Response(params?: InventoryManagementUpdateInventoryHistorics$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return inventoryManagementUpdateInventoryHistorics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementUpdateInventoryHistorics$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inventoryManagementUpdateInventoryHistorics(params?: InventoryManagementUpdateInventoryHistorics$Params, context?: HttpContext): Observable<boolean> {
    return this.inventoryManagementUpdateInventoryHistorics$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `inventoryManagementGetAllInventoryHistorics()` */
  static readonly InventoryManagementGetAllInventoryHistoricsPath = '/api/admin/InventoryManagement/GetAllInventoryHistorics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementGetAllInventoryHistorics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryManagementGetAllInventoryHistorics$Plain$Response(params?: InventoryManagementGetAllInventoryHistorics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetInventoryHistoricDto>> {
    return inventoryManagementGetAllInventoryHistorics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementGetAllInventoryHistorics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryManagementGetAllInventoryHistorics$Plain(params?: InventoryManagementGetAllInventoryHistorics$Plain$Params, context?: HttpContext): Observable<OutputGetInventoryHistoricDto> {
    return this.inventoryManagementGetAllInventoryHistorics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetInventoryHistoricDto>): OutputGetInventoryHistoricDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryManagementGetAllInventoryHistorics()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryManagementGetAllInventoryHistorics$Response(params?: InventoryManagementGetAllInventoryHistorics$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetInventoryHistoricDto>> {
    return inventoryManagementGetAllInventoryHistorics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryManagementGetAllInventoryHistorics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryManagementGetAllInventoryHistorics(params?: InventoryManagementGetAllInventoryHistorics$Params, context?: HttpContext): Observable<OutputGetInventoryHistoricDto> {
    return this.inventoryManagementGetAllInventoryHistorics$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetInventoryHistoricDto>): OutputGetInventoryHistoricDto => r.body)
    );
  }

}
